import {useEffect, useRef} from 'react'
import {SentimentEnum} from '@/utilities/constants/enums'
import {StyledBackground, StyledVideo} from './style'
import {PatientType} from '@/features/patient/types'

interface EmpathyAvatarProps {
    sentiment: SentimentEnum | null
    setIsPlayingAnimation: (value: boolean) => void
    patient: PatientType
    lastMessagePatientTextLength: number | undefined
    isAnimating: boolean
}

const ANGER_TIME = 0
const DISGUST_TIME = 4
const FEAR_TIME = 8
const JOY_TIME = 12
const SADNESS_TIME = 16
const SURPRISE_TIME = 20

const ANIMATION_DURATION = 4
const WORDS_PER_MINUTE = 300
const SECONDS_TO_DELAY = 6000

const getSentimentTime = (sentiment: SentimentEnum | null) => {
    switch (sentiment?.toLowerCase()) {
        case SentimentEnum.Anger.toLowerCase():
            return ANGER_TIME
        case SentimentEnum.Disgust.toLowerCase():
            return DISGUST_TIME
        case SentimentEnum.Fear.toLowerCase():
            return FEAR_TIME
        case SentimentEnum.Joy.toLowerCase():
            return JOY_TIME
        case SentimentEnum.Sadness.toLowerCase():
            return SADNESS_TIME
        case SentimentEnum.Surprise.toLowerCase():
            return SURPRISE_TIME
        default:
            return JOY_TIME
    }
}

export const EmpathyAvatar = ({
    sentiment,
    setIsPlayingAnimation,
    patient,
    lastMessagePatientTextLength,
    isAnimating
}: EmpathyAvatarProps) => {
    const videoRef = useRef<HTMLVideoElement>(null)

    const readingTimeInMilliseconds = () => {
        const words = lastMessagePatientTextLength ?? 0
        const readingTimeInMinutes = words / WORDS_PER_MINUTE

        return Math.round(readingTimeInMinutes * 60 * 1000)
    }

    useEffect(() => {
        if (sentiment) {
            const video = videoRef.current
            if (video && video.src) {
                const currentTime = getSentimentTime(sentiment)
                video.currentTime = currentTime
                video.play()
                setIsPlayingAnimation(true)
                // This is to ensure that the video is stopped exactly when the animation is completed
                const interval = setInterval(() => {
                    if (video.currentTime >= currentTime + ANIMATION_DURATION) {
                        video.pause()
                        setIsPlayingAnimation(false)
                        clearInterval(interval)
                    }
                }, 1)
            } else {
                setIsPlayingAnimation(false)
            }
        } else {
            setIsPlayingAnimation(false)
        }
    }, [sentiment])

    // This is needed since on Safari the video won't appear with autoPlay={false}
    useEffect(() => {
        setIsPlayingAnimation(false)
        const video = videoRef.current
        if (video && video.src) {
            video.play()
            video.pause()
            video.currentTime = JOY_TIME
        }
    }, [videoRef.current?.src])

    useEffect(() => {
        const video = videoRef.current
        if (isAnimating && video) {
            const time = readingTimeInMilliseconds()
            if (time > 4000) {
                setTimeout(() => {
                    video.pause()
                    setTimeout(
                        () => {
                            video.play()
                            video.playbackRate = 0.5
                        },
                        Math.max(time - SECONDS_TO_DELAY, 0)
                    )
                }, 2000)
            }
        } else {
            if (video) {
                video.playbackRate = 1
            }
        }
    }, [isAnimating, videoRef])

    return (
        <StyledBackground $previewImage={patient?.videoPreview ?? ''}>
            <StyledVideo
                ref={videoRef}
                src={patient?.video ? `${patient?.video}#t=${JOY_TIME}` : undefined}
                controls={false}
                autoPlay={false}
                playsInline
                muted
                onDoubleClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
            />
        </StyledBackground>
    )
}
