import styled, {css, keyframes} from 'styled-components'

type StyledSpinnerProps = {
    color?: string
    size?: number
}

const spinAnimation = () => keyframes`
    to {
        transform: rotate(360deg);
    }
`

export const StyledSpinner = styled.div<StyledSpinnerProps>`
    ${({color, size}) => css`
        border: 4px solid ${color};
        border-top: 4px solid transparent;
        border-radius: 50%;
        width: ${size}px;
        height: ${size}px;
        animation: ${spinAnimation} 1s linear infinite;
    `}
`

export const StyledSpinnerOverlay = styled.div`
    ${({theme}) => css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${theme.palette.primary[950]};
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    `}
`
