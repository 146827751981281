import React, {useRef, useState} from 'react'
import {
    StyledArrow,
    StyledContainerButton,
    StyledContainerSlider,
    StyledDot,
    StyledImageSlide,
    StyledSubtitleSlide,
    StyledTitleSlide,
    StyledWrapper
} from '@components/ui/slider/styles.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'

interface SliderProps {
    images: {
        image: string
        title: string
        subtitle: string
    }[]
    callback: () => void
    defaultStep: number
}

export const Slider: React.FC<SliderProps> = ({images, callback, defaultStep}) => {
    const {t} = useTranslation()
    const [currentSlide, setCurrentSlide] = useState(defaultStep)
    const dragStartRef = useRef(0)
    const dragEndRef = useRef(0)

    const handleDragStart = (e: React.TouchEvent<HTMLDivElement>) => {
        dragStartRef.current = e.touches[0].clientX
    }

    const handleDragEnd = (e: React.TouchEvent<HTMLDivElement>) => {
        dragEndRef.current = e.changedTouches[0].clientX
        const distance = dragStartRef.current - dragEndRef.current

        if (Math.abs(distance) > 100) {
            if (distance > 0) {
                goToNextSlide()
            } else {
                goToPrevSlide()
            }
        }
    }

    const goToSlide = (index: number) => {
        setCurrentSlide(index)
    }

    const goToPrevSlide = () => {
        if (currentSlide === 0) return
        setCurrentSlide(prevSlide => prevSlide - 1)
    }

    const goToNextSlide = () => {
        if (currentSlide === images.length - 1 && callback) {
            callback()
        } else {
            setCurrentSlide(prevSlide => prevSlide + 1)
        }
    }

    return (
        <StyledContainerSlider onTouchStart={handleDragStart} onTouchEnd={handleDragEnd}>
            <Flexbox direction={'column'} align={'center'} justify={'center'} gap={8}>
                <Flexbox gap={1}>
                    {images.map((_, index) => (
                        <StyledDot key={index} $isActive={index <= currentSlide} onClick={() => goToSlide(index)} />
                    ))}
                </Flexbox>
                {images.map((image, index) => (
                    <StyledWrapper $isVisible={index === currentSlide} key={index}>
                        <StyledImageSlide src={image.image} />

                        <div className={'description'}>
                            <StyledTitleSlide>{image.title}</StyledTitleSlide>
                            <StyledSubtitleSlide>{image.subtitle}</StyledSubtitleSlide>
                        </div>

                        <StyledArrow className="left" onClick={goToPrevSlide} />
                        <StyledArrow className="right" onClick={goToNextSlide} />

                        <StyledContainerButton $currentSlide={currentSlide}>
                            {currentSlide !== 0 ? (
                                <div className={'back-button'} onClick={goToPrevSlide}>
                                    {t('commons:back')}
                                </div>
                            ) : (
                                <div />
                            )}
                            <div className={'next-button'} onClick={goToNextSlide}>
                                {t('commons:next')}
                            </div>
                        </StyledContainerButton>
                    </StyledWrapper>
                ))}
            </Flexbox>
        </StyledContainerSlider>
    )
}
