import {StyledButtonToClose, StyledViewInformationButton} from '@components/ui/patient-card/style.ts'
import {Chevron} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {FC, useState} from 'react'
import {PatientType} from '@/features/patient/types.ts'
import {PatientInformation} from '../../patient-information/PatientInformation'

type ButtonPatientCardProps = {
    patient: PatientType
}
export const DropdownPatientCard: FC<ButtonPatientCardProps> = ({patient}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const {t} = useTranslation()

    return (
        <StyledViewInformationButton
            $isDropdownOpen={isDropdownOpen}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
            {!isDropdownOpen ? (
                <StyledButtonToClose $isDropdownOpen={isDropdownOpen}>
                    <p>{t('patients:card:view_more')}</p>
                    <Chevron fill={'none'} viewBox="0 0 11 7" />
                </StyledButtonToClose>
            ) : (
                <>
                    <PatientInformation patient={patient} />

                    <StyledButtonToClose onClick={() => setIsDropdownOpen(false)} $isDropdownOpen={isDropdownOpen}>
                        <p>{t('patients:card:close')}</p>

                        <Chevron fill={'none'} viewBox="0 0 11 7" />
                    </StyledButtonToClose>
                </>
            )}
        </StyledViewInformationButton>
    )
}
