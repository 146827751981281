import {forwardRef, useRef, useState, ChangeEvent, TextareaHTMLAttributes, useEffect, useMemo} from 'react'
import styled, {css} from 'styled-components'
import {useGeneralStore} from '@/store/useGeneralStore.tsx'
import {textAreaMaxChars} from '@/theme/theme'

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
    placeholder?: string
    name?: string
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
    onBlur?: () => void
    disabled?: boolean
    maxRows?: number
    resetRows?: boolean
    setResetRows?: (value: boolean) => void
}

type StyledTextAreaProps = {
    textLength: number
}

const StyledTextArea = styled.textarea<StyledTextAreaProps>`
    ${({theme, textLength}) => css`
        min-height: ${textLength > 41 ? '40px' : '20px'};
        padding: 0 ${theme.spacing}px;
        width: 100%;
        resize: none;
        border: none;

        &::placeholder {
            color: ${theme.palette.neutral[400]};
        }

        &:focus {
            outline: none;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    `}
`

const StyledCounter = styled.p`
    ${({theme}) => css`
        font-size: ${theme.typography.textXs}px;
        align-self: end;
        font-weight: 700;
        margin-right: ${theme.spacing * 2}px;
    `}
`

export const ResizableTextarea = forwardRef<HTMLTextAreaElement, Props>(function TextArea(
    {placeholder, name, onChange, onBlur, disabled, maxRows = 3, resetRows = false, setResetRows, ...rest}: Props,
    forwardedRef
) {
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const ref = forwardedRef ?? textareaRef
    const [rows, setRows] = useState<number>(1)
    const [text, setText] = useState<string>('')
    const {setGeneralData} = useGeneralStore()

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        let value = e.target.value

        if (value.length > textAreaMaxChars) {
            value = value.slice(0, textAreaMaxChars)
        }
        setText(value)
        setRows(value.split('\n').length)
        if (maxRows !== null && value.split('\n').length >= maxRows) {
            setRows(maxRows)
        }
        onChange && onChange(e)
    }

    useEffect(() => {
        if (resetRows) {
            setRows(1)
            setText('')
            setResetRows && setResetRows(false)
        }
    }, [resetRows])

    useEffect(() => {
        setGeneralData('rows', rows)
    }, [rows])

    const textLength = useMemo(() => text?.length, [text])

    return (
        <>
            <StyledTextArea
                name={name}
                placeholder={placeholder}
                ref={ref}
                value={text}
                rows={rows}
                onBlur={onBlur}
                onChange={handleChange}
                disabled={disabled}
                maxLength={textAreaMaxChars}
                textLength={textLength}
                {...rest}
            />
            <StyledCounter>
                {text.length}/{textAreaMaxChars}
            </StyledCounter>
        </>
    )
})
