import React, {useMemo} from 'react'
import {Outlet} from 'react-router-dom'
import useOrientation, {orientationType} from '@hooks/useOrientation.ts'
import {LandscapeView} from '@components/ui/landscape-view/LandscapeView.tsx'

export const PublicLayout: React.FC = () => {
    const orientation = useOrientation()

    const isOrientationLandscape = useMemo(() => {
        return orientation !== orientationType.portraitPrimary && orientation !== orientationType.portrait
    }, [orientation])

    if (!isOrientationLandscape) {
        return <Outlet />
    } else {
        return <LandscapeView />
    }
}
