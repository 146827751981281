import styled, {css} from 'styled-components'

export const StyledLandscapeView = styled.div`
    ${({theme: {gradients, spacing}}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        background: ${gradients.backgroundDark};
        gap: ${spacing * 6}px;
    `}
`

export const StyledDescriptionOnPortrait = styled.p`
    ${({theme}) => css`
        color: ${theme.palette.neutral.white};
        ${theme.typography.displayXs};
        font-weight: 700;
    `}
`
