import {
    StyledBadge,
    StyledContainerMineMessage,
    StyledMessageContainer,
    StyledName,
    StyledText
} from '@/features/conversation/components/conversation-body/components/message/style.ts'
import {FC, useMemo} from 'react'
import {MessageType} from '@/features/conversation/types.ts'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

type MineMessageProps = {
    message: MessageType
}
export const MineMessage: FC<MineMessageProps> = ({message}) => {
    const {t} = useTranslation()

    const score = useMemo(() => message.relevanceScore, [message.relevanceScore])
    const getColorForScore = () => {
        if (!score && score !== 0) {
            return 'neutral'
        } else {
            if (score <= 40) {
                return 'danger'
            } else if (score >= 41 && score <= 84) {
                return 'warning'
            } else if (score >= 85) {
                return 'success'
            }
        }
    }

    return (
        <StyledMessageContainer>
            <Flexbox gap={1} align={'baseline'} justify={'flex-end'}>
                <StyledName>{t('conversation:history:you')}</StyledName>
                <StyledBadge color={getColorForScore() ?? 'neutral'} />
            </Flexbox>
            <StyledContainerMineMessage color={getColorForScore() ?? 'neutral'}>
                <StyledText $isMine>{message.text}</StyledText>
            </StyledContainerMineMessage>
        </StyledMessageContainer>
    )
}
