import {StyledHeaderForConversation} from '@components/commons/header/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {AvatarChatIcon, ConversationHistoryIcon, HistoryIcon, LogoIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {useGeneralStore} from '@/store/useGeneralStore.tsx'
import {FC, useEffect, useState} from 'react'
import {PatientInformation} from '@/components/ui/patient-information/PatientInformation'
import {PatientType} from '@/features/patient/types'
import {StyledInformationModal} from './style'
import {Tooltip} from '@components/ui/tooltip/Tooltip.tsx'

type ConversationHeaderProps = {
    patient: PatientType
    conditionToSeeAgreeModal: boolean
    setIsPlayingAnimation: (value: boolean) => void
}

export const ConversationHeader: FC<ConversationHeaderProps> = ({
    patient,
    conditionToSeeAgreeModal,
    setIsPlayingAnimation
}) => {
    const {t} = useTranslation()
    const {showTooltipToLeaveChat, isFullConversation, isTyping, setGeneralData} = useGeneralStore()
    const [isInformationModalOpen, setIsInformationModalOpen] = useState(false)

    useEffect(() => {
        setTimeout(() => setGeneralData('showTooltipToLeaveChat', false), 5000)
    }, [])

    return (
        <>
            <StyledHeaderForConversation
                $isFullConversation={isFullConversation}
                $conditionToSeeAgreeModal={conditionToSeeAgreeModal}
            >
                <Flexbox justify={'space-between'} align={'center'}>
                    <Tooltip
                        variant={isFullConversation ? 'dark' : 'light'}
                        open={showTooltipToLeaveChat}
                        trigger={
                            <LogoIcon
                                className={'logo'}
                                width={44}
                                height={29}
                                viewBox="0 0 465 285"
                                onClick={() => !isTyping && setGeneralData('openModalToLeave', true)}
                            />
                        }
                        content={t('commons:leave_conversation_tooltip')}
                        side={'right'}
                    />
                    <Flexbox gap={2}>
                        <div className={'icon-container'} onClick={() => setIsInformationModalOpen(true)}>
                            <HistoryIcon size={26} fill={'none'} viewBox="0 0 30 30" className={'history-icon'} />
                            {t('conversation:main:patient_info')}
                        </div>

                        <div
                            className={'icon-container'}
                            onClick={() => {
                                setGeneralData('isFullConversation', !isFullConversation)
                                setIsPlayingAnimation(false)
                            }}
                        >
                            {isFullConversation ? (
                                <>
                                    <AvatarChatIcon size={26} viewBox="0 0 30 30" fill={'none'} />
                                    {t('conversation:main:avatar_conversation')}
                                </>
                            ) : (
                                <>
                                    <ConversationHistoryIcon size={26} viewBox="0 0 30 30" fill={'none'} />
                                    {t('conversation:main:classic_conversation')}
                                </>
                            )}
                        </div>
                    </Flexbox>
                </Flexbox>
            </StyledHeaderForConversation>
            {isInformationModalOpen && (
                <StyledInformationModal width={400} onClose={() => setIsInformationModalOpen(false)}>
                    <PatientInformation patient={patient} />
                </StyledInformationModal>
            )}
        </>
    )
}
