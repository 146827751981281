import {StyledContainer} from '@pages/welcome/styles.ts'
import {useTranslation} from 'react-i18next'
import {StyledLink, StyledLogoIcon, StyledThankYouSubtitle, StyledThankYouTitle} from '@pages/thank-you/styles.ts'
import {useNavigate} from 'react-router-dom'
import {useGeneralStore} from '@/store/useGeneralStore.tsx'
import {routes} from '@utilities/constants/routes.tsx'

export const ThankYou = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {setGeneralData} = useGeneralStore()

    const selectOtherPatients = () => {
        setGeneralData('currentRouteIndex', 4)
        navigate(routes.PATIENTS.path)
    }

    return (
        <StyledContainer align={'center'} justify={'center'} gap={6} direction={'column'}>
            <StyledThankYouTitle>{t('thanks_page:title')}</StyledThankYouTitle>
            <StyledLogoIcon viewBox="0 0 465 285" />

            <StyledThankYouSubtitle>{t('thanks_page:subtitle')}</StyledThankYouSubtitle>

            <StyledLink onClick={selectOtherPatients}>{t('thanks_page:select_another_patient')}</StyledLink>
        </StyledContainer>
    )
}
