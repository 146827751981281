import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from 'react-i18next'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {StyledInformationButton, StyledInformationTitle, StyledInformationWrapper} from '@pages/information/styles.ts'
import {useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import useSequentialRoutes from '@hooks/useSequentialRoutes.tsx'
import {BackButton} from '@components/ui/back-button/BackButton.tsx'
import {ExplanationTabs} from '@/utilities/constants/enums'

export const Information = () => {
    const {t} = useTranslation()
    const [agree, setAgree] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const {goToPreviousRoute, goToNextRoute} = useSequentialRoutes(navigate, location)

    return (
        <>
            <StyledInformationWrapper direction={'column'} gap={6} justify={'flex-start'}>
                <BackButton onBack={() => goToPreviousRoute(`?defaultStep=${ExplanationTabs.Third}`)} />
                <div className={'text-wrapper'}>
                    <StyledInformationTitle>{t('information_page:title')}</StyledInformationTitle>

                    <p>{t('information_page:content')}</p>
                </div>

                <Flexbox align={'flex-start'} direction={'column'} gap={6} className={'wrapper-footer'}>
                    <Checkbox
                        name={'agree'}
                        id={'checkbox'}
                        label={t('information_page:agree')}
                        onChange={() => setAgree(!agree)}
                    />

                    <StyledInformationButton variant={'primary'} disabled={!agree} onClick={() => goToNextRoute()}>
                        {t('information_page:proceed')}
                    </StyledInformationButton>
                </Flexbox>
            </StyledInformationWrapper>
        </>
    )
}
