import {Slider} from '@components/ui/slider/Slider.tsx'
import {useTranslation} from 'react-i18next'
import useSequentialRoutes from '@hooks/useSequentialRoutes.tsx'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import img1 from '@assets/images/img-step1.png'
import img2 from '@assets/images/img-step2.png'
import img3 from '@assets/images/img-step3.png'

export const Explanation = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const {goToNextRoute} = useSequentialRoutes(navigate, location)
    const [searchParams] = useSearchParams()

    const handleNext = () => {
        goToNextRoute()
    }

    const images = [
        {
            image: img1,
            title: t('explanation_page:1:title'),
            subtitle: t('explanation_page:1:subtitle')
        },
        {
            image: img2,
            title: t('explanation_page:2:title'),
            subtitle: t('explanation_page:2:subtitle')
        },
        {
            image: img3,
            title: t('explanation_page:3:title'),
            subtitle: t('explanation_page:3:subtitle')
        }
    ]

    const defaultStepParam = searchParams.get('defaultStep')
    const defaultStep = defaultStepParam ? parseInt(defaultStepParam) : 0

    return <Slider defaultStep={defaultStep} images={images} callback={() => handleNext()} />
}
