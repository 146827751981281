import {StateCreator} from 'zustand'
import {GeneralStoreActions, GeneralStoreState} from '@/store/slices/types.ts'

const initialState = {
    isFullConversation: false,
    currentRouteIndex: 0,
    sid: '',
    isSessionValid: false,
    isConversationValid: false,
    selectedPatient: 1,
    background: 'backgroundDark',
    conversationId: 0,
    openModalToLeave: false,
    showTooltipToLeaveChat: true,
    rows: 1,
    isTyping: false
} satisfies GeneralStoreState

export const createGeneralDataSlice: StateCreator<GeneralStoreState & GeneralStoreActions> = set => ({
    ...initialState,
    setGeneralData: (key, value) => set(() => ({[key]: value}))
})
