import styled, {css} from 'styled-components'
import {EmojiIcon} from '@/components/ui/icon/Icon'

export const StyledEmojiIcon = styled(EmojiIcon)`
    ${() => css`
        cursor: pointer;
    `}
`

interface StyledSendButtonProps {
    disabled: boolean
}
export const StyledSendButton = styled.button<StyledSendButtonProps>`
    ${({theme: {palette, spacing}, disabled}) => css`
        width: 40px;
        height: 40px;
        background-color: ${palette.primary[900]};
        border-radius: 99px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: ${spacing}px;
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        opacity: ${disabled && 0.3};
    `}
`

type StyledInputContainerProps = {
    disabled: boolean
}

export const StyledInputContainer = styled.form<StyledInputContainerProps>`
    ${({theme: {gradients, palette, spacing}, disabled}) => css`
        width: 100%;
        padding: ${spacing * 2}px ${spacing * 5}px;
        background-color: ${palette.secondary[25]};
        bottom: 0;
        transition: bottom 0.3s;
        left: 0;
        z-index: 10;
        display: grid;
        grid-template-columns: auto 40px;
        gap: ${spacing * 2}px;
        align-items: center;

        .input-wrapper {
            width: 100%;
            border-radius: ${spacing * 5.5}px;
            border: 1px solid transparent;
            background: ${gradients[15]};
            min-height: 46px;
            padding: ${spacing}px;
        }

        .emoji-icon {
            cursor: pointer;
            margin-right: ${spacing * 2}px;
        }

        ${disabled &&
        css`
            opacity: 0.5;

            .input-wrapper {
                border: 1px solid ${palette.neutral[400]};
            }

            .emoji-icon {
                stroke: ${palette.neutral[400]};
                cursor: not-allowed;
            }
        `}
    `}
`
