import {useEffect, useState} from 'react'
import {routes as routesFile} from '@utilities/constants/routes.tsx'
import {useGeneralStore} from '@/store/useGeneralStore.tsx'

/**
 *How to use it:
 **example:**
 *     if you don't want to use the default routes from the routes file you can pass an array of routes as the third argument
 *     const navigate = useNavigate()
 *     const location = useLocation()
 *     const {goToNextRoute, goToPreviousRoute} = useSequentialRoutes(navigate, location)
 **/

const useSequentialRoutes = (
    navigate: (route: string) => void,
    location: {pathname: string},
    otherRoutes?: string[]
) => {
    const {setGeneralData, currentRouteIndex} = useGeneralStore()

    const routesToMap = Object.values(routesFile).map(route => route.path)
    const routes = otherRoutes && otherRoutes.length > 0 ? otherRoutes : routesToMap
    const [currentRoute] = useState(currentRouteIndex)

    useEffect(() => {
        // If the user scans the QR code again,
        // we need to reset the session and go back to the initial welcome page
        if (location.pathname === '/' && currentRouteIndex !== 0) {
            localStorage.clear()
            window.location.reload()
        } else if (location.pathname !== routes[currentRoute]) {
            navigate(routes[currentRoute])
        }
    }, [location.pathname, currentRoute, navigate])

    //this function will navigate to the next route in the array
    const goToNextRoute = () => {
        setGeneralData('currentRouteIndex', currentRoute + 1)
        navigate(routes[currentRoute + 1])
    }

    //this function will navigate to the previous route in the array
    const goToPreviousRoute = (queryString?: string) => {
        setGeneralData('currentRouteIndex', currentRoute - 1)
        navigate(`${routes[currentRoute - 1]}${queryString ?? ''}`)
    }

    //returning the current route index and the functions to navigate to the next and previous routes
    return {goToNextRoute, goToPreviousRoute}
}

export default useSequentialRoutes
