import styled, {css, keyframes} from 'styled-components'
import {DefaultTheme} from 'styled-components/dist/types'

type WithIsMineProps = {
    theme: DefaultTheme
    $isMine: boolean
}

type StyledStatusProps = {
    theme: DefaultTheme
    color: keyof DefaultTheme['palette']
}

export const StyledMainWrapper = styled.div<WithIsMineProps>`
    ${({theme: {spacing, userSelect}, $isMine}) => css`
        ${userSelect.none}
        display: grid;
        grid-template-columns: 1fr;
        gap: ${spacing * 2}px;

        ${$isMine
            ? css`
                  margin-left: auto;
              `
            : css`
                  margin-right: auto;
              `}
    `}
`
export const StyledText = styled('span')<WithIsMineProps>`
    ${({$isMine}) => css`
        text-align: ${$isMine ? 'right' : 'left'};
        font-weight: 500;
        white-space: pre-line;
    `}
`

export const StyledMessageContainer = styled.div`
    ${() => css`
        width: 100%;
    `}
`

export const StyledName = styled.div`
    ${({theme}) => css`
        ${theme.typography.textXs}
        font-weight: 500;
        text-transform: uppercase;
        padding-bottom: ${theme.spacing / 2}px;
    `}
`

const progressAnimation = keyframes`
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
`

export const StyledContainerMineMessage = styled.div<StyledStatusProps>`
    ${({theme, color}) => css`
        background-color: ${theme.palette.secondary[25]};
        border-radius: ${theme.spacing * 3}px 0 ${theme.spacing * 3}px 0;

        padding: ${theme.spacing * 3}px ${theme.spacing * 2}px;
        display: inline-block;
        position: relative;
        max-width: 263px;
        word-wrap: break-word;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 5px;
            background: linear-gradient(90deg, ${theme.palette[color][500]} 0%, rgba(255, 168, 0, 0) 100%);
            border-bottom-left-radius: ${theme.spacing * 3}px;
            border-bottom-right-radius: ${theme.spacing * 3}px;
            animation: ${progressAnimation} 1s linear forwards;
        }
    `}
`

type StyledContainerPatientMessageProps = {
    $isFirstMessage: boolean
}
export const StyledContainerPatientMessage = styled.div<StyledContainerPatientMessageProps>`
    ${({theme, $isFirstMessage}) => css`
        background-color: ${$isFirstMessage ? 'transparent' : theme.palette.secondary[25]};
        border-radius: ${theme.spacing * 3}px;

        padding: ${theme.spacing * 3}px;
        display: inline-block;
        position: relative;
        max-width: 263px;
    `}
`

export const StyledBadge = styled.div<StyledStatusProps>`
    ${({theme, color}) => css`
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: ${theme.palette[color][500]};
    `}
`
export const StyledReadMore = styled.div`
    ${({theme}) => css`
        ${theme.typography.textSm}
        font-weight: 600;
        text-decoration: underline;
        color: ${theme.palette.primary[500]};
        cursor: pointer;
        padding-top: ${theme.spacing}px;
    `}
`

export const StyledErrorMessage = styled.div`
    ${({theme}) => css`
        color: ${theme.palette.danger[500]};
        font-weight: 700;
    `}
`

type StyledTypingMessageProps = {
    $isFullConversation: boolean
}

export const StyledTypingMessage = styled.div<StyledTypingMessageProps>`
    ${({theme, $isFullConversation}) => css`
        margin-top: ${theme.spacing * 3}px;
        margin-bottom: ${!$isFullConversation && theme.spacing * 3}px;
        display: flex;
        flex-direction: column;
        align-items: ${!$isFullConversation ? 'center' : 'flex-start'};
        gap: ${theme.spacing}px;
        font-weight: 700;
    `}
`
