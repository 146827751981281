export const gradients = {
    backgroundDark: 'linear-gradient(180deg, #195698 0%, #031931 100%)', //backgroundDark
    backgroundLight: 'linear-gradient(180deg, #FFFFFF 0%, #C9CEFF 100%)', //backgroundLight
    15: 'linear-gradient(#EFEFFF 0 0) padding-box, linear-gradient(90deg, #1f7cde 0%, #a3cfff 100%) border-box', //input-wrapper
    20: 'linear-gradient(180deg, #2b91f8 0%, #0064ca 100%)',
    25: 'linear-gradient(180deg, #e8eef3 0%, #c4cfda 100%)',
    30: 'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0.15))', //isAtTop
    35: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 1))', //isAtBottom
    40: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0.15))', //isScrollbarVisible
    45: 'radial-gradient(transparent, transparent 60%, white 60%), linear-gradient(to top, #15387aff 0%, transparent 30%, transparent 50%, transparent 100%)',
    50: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(15, 41, 91, 0.8) 100%)', //photoCard
    55: 'linear-gradient(90deg, rgba(217, 217, 217, 0.312) 0%, rgba(255, 255, 255, 0.08) 100%)', //buttonCard
    60: 'linear-gradient(4deg, #102D62 30%, rgba(25, 63, 104, 0) 100%)',
    65: 'linear-gradient(4deg, #102D62 4%, rgba(25, 63, 104, 0) 100%)'
}
