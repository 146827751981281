import {useEffect, useState} from 'react'

const useAnimatedText = (initialText: string, isAnimated: boolean, onAnimationComplete: () => void) => {
    const [displayedText, setDisplayedText] = useState('')
    const [isAnimating, setIsAnimating] = useState(false)

    useEffect(() => {
        if (isAnimated) {
            const words = initialText.split(' ')
            let currentText = ''
            setIsAnimating(true)

            const animationInterval = setInterval(() => {
                if (words.length === 0) {
                    setIsAnimating(false)
                    clearInterval(animationInterval)
                    onAnimationComplete()
                } else {
                    currentText += words.shift() + ' '
                    setDisplayedText(currentText)
                }
            }, 150)

            return () => clearInterval(animationInterval)
        } else {
            setDisplayedText(initialText)
        }
    }, [initialText, isAnimated])

    return {displayedText, isAnimating}
}

export default useAnimatedText
