import {DisclaimerIcon} from '@components/ui/icon/Icon.tsx'
import {useTranslation} from 'react-i18next'
import {StyledStartButton} from '@pages/welcome/styles.ts'
import {useLocation, useNavigate} from 'react-router-dom'
import useSequentialRoutes from '@hooks/useSequentialRoutes.tsx'
import {StyledContainerDisclaimerPage} from '@pages/disclaimer/style.ts'

export const Disclaimer = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const {goToNextRoute} = useSequentialRoutes(navigate, location)

    return (
        <StyledContainerDisclaimerPage direction={'column'} align={'center'} justify={'center'} gap={8}>
            <DisclaimerIcon width="55" height="48" viewBox="0 0 55 48" />
            <p>{t('disclaimer:title')}</p>
            <span>{t('disclaimer:content')}</span>
            <StyledStartButton
                variant={'primary'}
                onClick={goToNextRoute}
                style={{
                    width: '100%',
                    maxWidth: '300px'
                }}
            >
                {t('disclaimer:agree')}
            </StyledStartButton>
        </StyledContainerDisclaimerPage>
    )
}
