import {useMutation} from '@tanstack/react-query'
import {MUTATION_KEYS} from '@/features/validate-session/keys.ts'
import {VALIDATE_API} from '@/features/validate-session/services/validate.http.ts'

export const useValidateSession = () => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.VALIDATE_SESSION],
        mutationFn: (sid: string) => VALIDATE_API.validateSession(sid)
    })
}
