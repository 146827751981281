import styled, {css} from 'styled-components'

type StyledMineMessagePlaceholderProps = {
    $isPlaceholder: boolean
}
export const StyledMineMessage = styled.p<StyledMineMessagePlaceholderProps>`
    ${({theme: {typography, palette}, $isPlaceholder}) => css`
        ${typography.textMd};
        color: ${$isPlaceholder ? palette.neutral[400] : palette.neutral.black};
        text-align: center;
        max-height: 50px;
        width: 100%;

        @media (min-width: 768px) {
            max-height: 280px;
        }
    `}
`

export const StyledFullConversationWrapper = styled.div`
    ${({theme}) => css`
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing * 4}px;
        flex-grow: 1;
        overflow-y: scroll;
        margin-bottom: ${theme.spacing * 2.5}px;
        padding: ${theme.spacing * 7.5}px;
        ${theme.userSelect.none}
    `}
`

export const StyledConversationTitle = styled.h3`
    ${({theme: {typography, palette, userSelect}}) => css`
        ${typography.textXl};
        font-weight: 600;
        color: ${palette.neutral[900]};
        text-align: center;
        ${userSelect.none}
    `}
`
