import styled, {css, DefaultTheme} from 'styled-components'
import {BackgroundType} from '@/store/slices/types.ts'

type StyledMainProps = {
    theme: DefaultTheme
    background?: BackgroundType
    $isSelectPatientPage: boolean
}
export const StyledMain = styled.div<StyledMainProps>`
    ${({theme, background, $isSelectPatientPage}) => css`
        overflow-y: auto;
        height: ${$isSelectPatientPage ? '100vh' : '100%'};
        padding: ${theme.spacing * 6}px 0;
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing * 5}px;
        background: ${background ? theme.gradients[background] : 'transparent'};
        color: ${background === 'backgroundDark' ? theme.palette.neutral['white'] : theme.palette.primary['950']};
    `}
`
