import {LogoIcon} from '@/components/ui/icon/Icon'
import {routes} from '@/utilities/constants/routes'
import {HeaderWrapper, StyledContent} from '@components/commons/header/style.ts'
import {matchPath, useLocation} from 'react-router-dom'
import {BackgroundType} from '@/store/slices/types.ts'

export const Header = ({background}: {background: BackgroundType}) => {
    const location = useLocation()
    const conditionToSeeLogoIcon =
        !matchPath({path: routes.WELCOME.path}, location.pathname) &&
        !matchPath({path: routes.THANK_YOU.path}, location.pathname) &&
        !matchPath({path: routes.NO_AVAILABLE.path}, location.pathname)

    return (
        <HeaderWrapper>
            <StyledContent background={background}>
                {conditionToSeeLogoIcon && <LogoIcon className={'logo'} width={44} height={29} viewBox="0 0 465 285" />}
            </StyledContent>
        </HeaderWrapper>
    )
}
