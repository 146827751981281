import styled, {css, DefaultTheme} from 'styled-components'
import * as RTooltip from '@radix-ui/react-tooltip'

type StyledTooltipProps = {
    variant: 'light' | 'dark'
}

export const StyledTooltipContent = styled(RTooltip.Content)<StyledTooltipProps>`
    ${({theme: {spacing, typography, shadows, zIndex}}) => css`
        padding: ${spacing * 2}px ${spacing * 3}px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        box-shadow: ${shadows.lg};
        ${typography.textSm};
        font-weight: 600;
        z-index: ${zIndex.tooltip};
    `}
    ${({variant, theme}) => ColorStyles(theme)[variant]};
`

const ColorStyles = ({palette}: DefaultTheme) => ({
    light: css`
        background: ${palette.neutral.white};
        color: ${palette.neutral['700']};
        & svg {
            fill: ${palette.neutral.white};
        }
    `,
    dark: css`
        background: ${palette.neutral['900']};
        color: ${palette.neutral.white};
        & svg {
            fill: ${palette.neutral['900']};
        }
    `
})
