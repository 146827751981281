import {createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom'
import {PrivateRoute} from '@route-guards/private-route/PrivateRoute.tsx'
import {PublicRoute} from '@route-guards/public-route/PublicRoute.tsx'
import {PublicLayout} from '@layouts/public-layout/PublicLayout.tsx'
import {PrivateLayout} from '@layouts/private-layout/PrivateLayout.tsx'
import {routes} from '@utilities/constants/routes.tsx'

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/">
            <Route element={<PrivateRoute />}>
                <Route element={<PrivateLayout withHeader />}>
                    <Route {...routes.WELCOME} />
                    <Route {...routes.DISCLAIMER} />
                    <Route {...routes.EXPLANATION} />
                    <Route {...routes.INFORMATION} />
                    <Route {...routes.PATIENTS} />
                    <Route {...routes.CONVERSATION} />
                    <Route {...routes.THANK_YOU} />
                </Route>
            </Route>
            <Route element={<PublicRoute />}>
                <Route element={<PublicLayout />}>
                    <Route {...routes.NO_AVAILABLE} />
                </Route>
            </Route>
            <Route {...routes.NOT_FOUND} />
        </Route>
    )
)
