import {StyledConversationTitle, StyledFullConversationWrapper} from './style'
import {FC, RefObject} from 'react'
import {useGeneralStore} from '@/store/useGeneralStore.tsx'
import {useTranslation} from 'react-i18next'
import {MessageType} from '@/features/conversation/types.ts'
import {PatientType} from '@/features/patient/types.ts'
import {Message} from '@/features/conversation/components/conversation-body/components/message/Message.tsx'
import {ConversationHeader} from '../../conversation-header/ConversationHeader'
import {MainChat} from '@/features/conversation/components/conversation-body/components/main-chat/MainChat.tsx'

type ConversationBodyProps = {
    patient: PatientType
    conversationMessages: MessageType[]
    conversationWrapperRef: RefObject<HTMLDivElement>
    isTyping: boolean
    setIsPlayingAnimation: (value: boolean) => void
    conditionToSeeAgreeModal: boolean
    setConversationMessages: (value: (prevMessages: MessageType[]) => MessageType[]) => void
    isPlayingAnimation: boolean
    onSend: (message: MessageType) => void
}

export const ConversationBody: FC<ConversationBodyProps> = ({
    patient,
    conversationMessages,
    conversationWrapperRef,
    isTyping,
    setIsPlayingAnimation,
    conditionToSeeAgreeModal,
    setConversationMessages,
    isPlayingAnimation,
    onSend
}) => {
    const {t} = useTranslation()
    const lastMessages = conversationMessages.slice(-2)
    const {isFullConversation} = useGeneralStore()

    const lastMessagePatient = lastMessages?.find(item => !item.isMine)
    const lastMessageMine = lastMessages?.find(item => item.isMine)

    const onAnimationComplete = () => {
        setConversationMessages(prevMessages => {
            return prevMessages?.map(messages => {
                return {
                    ...messages,
                    isAnimated: false
                }
            })
        })
    }

    if (isFullConversation) {
        return (
            <>
                <ConversationHeader
                    patient={patient as PatientType}
                    conditionToSeeAgreeModal={conditionToSeeAgreeModal}
                    setIsPlayingAnimation={setIsPlayingAnimation}
                />
                <StyledConversationTitle>
                    {t('conversation:history:title', {NAME: patient?.name})}
                </StyledConversationTitle>
                <StyledFullConversationWrapper ref={conversationWrapperRef} id={'full-conversation'}>
                    {conversationMessages.map((item, index) => (
                        <Message
                            key={index}
                            message={item}
                            patient={patient}
                            isTyping={isTyping}
                            onAnimationComplete={onAnimationComplete}
                        />
                    ))}
                </StyledFullConversationWrapper>
            </>
        )
    } else {
        return (
            <MainChat
                patient={patient}
                conditionToSeeAgreeModal={conditionToSeeAgreeModal}
                setIsPlayingAnimation={setIsPlayingAnimation}
                conversationMessages={conversationMessages}
                lastMessagePatient={lastMessagePatient}
                isTyping={isTyping}
                onAnimationComplete={onAnimationComplete}
                lastMessageMine={lastMessageMine}
                isPlayingAnimation={isPlayingAnimation}
                onSend={onSend}
            />
        )
    }
}
