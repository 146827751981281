import styled, {css} from 'styled-components'

export const StyledWrapperMainChat = styled.div`
    ${() => css`
        display: flex;
        flex-direction: column;
        height: 100vh;
    `}
`
export const StyledBody = styled.div`
    ${() => css`
        flex: 1;
        overflow-y: auto;
        scroll-behavior: smooth;
        overscroll-behavior: none;
    `}
`

type StyledFooterProps = {
    $haveGap: boolean
}
export const StyledFooter = styled.div<StyledFooterProps>`
    ${({$haveGap}) => css`
        position: fixed;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${$haveGap ? '20px' : '10px'};
    `}
`

export const StyledContainerMainChat = styled.div`
    ${({theme}) => css`
        background: ${theme.palette.neutral.white};
        height: 100vh;
        z-index: 0;
        overflow: hidden;
    `}
`

type StyledProps = {
    $isKeyboardOpen: boolean
    $rows?: number
    $windowInnerHeight?: number
}
export const StyledEllipses = styled.div`
    ${({theme}) => css`
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: ${theme.palette.primary[950]};
        clip-path: ellipse(130% 80% at 50% 0%);

        @media (max-height: 650px) {
            clip-path: ellipse(130% 75% at 50% 0%);
        }

        @media only screen and (min-device-width: 740px) and (max-device-width: 1000px) and (orientation: portrait) {
            clip-path: ellipse(130% 60% at 50% 0%);
        }
    `}
`

export const StyledVideo = styled.div<StyledProps>`
    ${({$isKeyboardOpen, $rows, $windowInnerHeight}) => {
        const bottom = () => {
            if ($rows) {
                if ($rows === 2) {
                    return '81px'
                } else if ($rows >= 3) {
                    return '100px'
                }
                return '65px'
            }
        }
        return css`
            position: absolute;
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 450px;
            z-index: 2;

            ${$isKeyboardOpen &&
            css`
                bottom: ${bottom()};
                height: ${$windowInnerHeight ?? 350}px;
                background-size: contain;
                background-position: bottom;
            `};

            @media (max-height: 650px) {
                height: 350px;
            }

            @media only screen and (min-device-width: 740px) and (max-device-width: 1000px) and (orientation: portrait) {
                height: 500px;
                top: 0;
                background-size: cover;
                background-position: center;
            }
        `
    }}
`
export const StyledGradient = styled.div<StyledProps>`
    ${({theme, $isKeyboardOpen, $rows}) => {
        const bottom = () => {
            if ($rows) {
                if ($rows === 2) {
                    return '81px'
                } else if ($rows >= 3) {
                    return '100px'
                }
                return '65px'
            }
        }
        return css`
            position: absolute;
            width: 100%;
            height: 69%;
            z-index: 3;
            background: ${theme.gradients[60]};

            ${$isKeyboardOpen &&
            css`
                bottom: ${bottom()};
                background: ${theme.gradients[65]};
            `};

            @media only screen and (min-device-width: 740px) and (max-device-width: 1000px) and (orientation: portrait) {
                height: 55%;
                top: 0;
                background: ${theme.gradients[60]};
            }
        `
    }}
`

export const StyledConversationHeaderWrapper = styled.div`
    ${() => css`
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: max-content;
        z-index: 4;
    `}
`

export const StyledMineMessageMainChat = styled.div`
    ${() => css`
        z-index: 4;
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 50px;
        max-width: 80%;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }

        @media (min-width: 768px) {
            max-height: 50%;
        }
    `}
`

type StyledBotMessageProps = {
    $errorMessageType: boolean
    $isFirstMessage: boolean
    $isKeyboardOpen?: boolean
}
export const StyledBotMessage = styled.div<StyledBotMessageProps>`
    ${({theme, $errorMessageType, $isFirstMessage, $isKeyboardOpen}) => css`
        color: ${$errorMessageType ? theme.palette.danger[500] : theme.palette.neutral.white};
        max-height: 200px;
        position: absolute;
        bottom: ${$isKeyboardOpen ? '100px' : '190px'};
        left: 50%;
        right: 0;
        transform: translateX(-50%);
        padding: ${theme.spacing * 2.5}px;
        z-index: 4;

        width: 80%;
        text-align: center;
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            display: none;
        }
        ${$isFirstMessage
            ? css`
                  ${theme.typography.displayXs};
                  font-weight: 500;
              `
            : css`
                  ${theme.typography.textMd};
                  font-weight: 400;
              `};
        @media only screen and (min-device-width: 740px) and (max-device-width: 1000px) and (orientation: portrait) {
            bottom: 0;
            top: 500px;
        }
    `}
`

export const MineLastMessage = styled.p`
    ${() => css`
        max-height: 50px;
        overflow-x: hidden;
        overflow-y: auto;
    `}
`
