import {StyledAgreeModal} from '@pages/conversation/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {AlertIcon} from '@components/ui/icon/Icon.tsx'
import {Trans, useTranslation} from 'react-i18next'
import {Button} from '@components/ui/button/Button.tsx'
import {FC} from 'react'

interface ModalAgreeToConditionProps {
    setOpenModalToAgree: (value: boolean) => void
}
export const ModalAgreeToCondition: FC<ModalAgreeToConditionProps> = ({setOpenModalToAgree}) => {
    const {t} = useTranslation()

    return (
        <StyledAgreeModal width={400}>
            <Flexbox direction={'column'} gap={8} justify={'center'} align={'flex-start'}>
                <Flexbox gap={2.5} direction={'column'}>
                    <h3 className={'title'}>
                        <AlertIcon />
                        {t('patients:modal_agree:title')}
                    </h3>
                    <Trans
                        i18nKey={'patients:modal_agree:content'}
                        components={{
                            strong: <strong />,
                            p: <p className={'content'} />
                        }}
                    />
                </Flexbox>

                <Button
                    className={'button'}
                    variant={'primary'}
                    onClick={() => {
                        setOpenModalToAgree(false)
                    }}
                >
                    {t('patients:modal_agree:understand')}
                </Button>
            </Flexbox>
        </StyledAgreeModal>
    )
}
