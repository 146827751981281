import {RouterProvider} from 'react-router-dom'
import {StyleSheetManager, ThemeProvider} from 'styled-components'
import {getAppTheme, GlobalStyles} from '@/theme/theme.ts'
import {router} from './router.tsx'
import {Toaster} from 'react-hot-toast'
import {useEffect} from 'react'

export default function App() {
    const themeName = 'light' //get it from the app reducer

    // Clear local storage on page unload
    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.clear() //for windows and android
            window.localStorage.clear() //for ios
        }

        window.addEventListener('beforeunload', handleBeforeUnload) //for windows and android
        window.addEventListener('pagehide', handleBeforeUnload) //for ios
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
            window.removeEventListener('pagehide', handleBeforeUnload)
        }
    }, [])

    return (
        <ThemeProvider theme={getAppTheme({name: themeName})}>
            <StyleSheetManager>
                <>
                    <Toaster />
                    <GlobalStyles />
                    <RouterProvider router={router} />
                </>
            </StyleSheetManager>
        </ThemeProvider>
    )
}
