import styled, {css} from 'styled-components'
import {BackgroundType} from '@/store/slices/types.ts'

type StyledMainWrapperProps = {
    background: BackgroundType
}
export const StyledMainWrapper = styled.div<StyledMainWrapperProps>`
    ${({theme: {spacing, typography, palette}, background}) => css`
        margin-bottom: ${spacing * 2.5}px;
        display: flex;
        align-items: center;
        gap: ${spacing}px;
        cursor: pointer;
        width: min-content;

        ${typography.textSm};
        font-weight: 500;

        .icon {
            width: 5px;
            height: 9px;
            margin-right: ${spacing}px;
            & path {
                stroke: ${background === 'backgroundDark' ? palette.neutral.white : palette.neutral.black};
            }
        }
    `}
`
