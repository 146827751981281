import {Outlet, useLocation} from 'react-router-dom'
import {StyledMain} from './styles'
import React, {useEffect, useMemo} from 'react'
import {Header} from '@components/commons/header/Header.tsx'
import {Container} from '@components/ui/container/Container.tsx'
import {routes} from '@utilities/constants/routes.tsx'
import {useGeneralStore} from '@/store/useGeneralStore.tsx'
import {BackgroundType} from '@/store/slices/types.ts'
import useOrientation, {orientationType} from '@hooks/useOrientation.ts'
import {LandscapeView} from '@components/ui/landscape-view/LandscapeView.tsx'

type PrivateLayoutProps = {
    withHeader?: boolean
}
const routesForBackground = {
    backgroundDark: [routes.WELCOME.path, routes.PATIENTS.path, routes.THANK_YOU.path, routes.DISCLAIMER.path],
    backgroundLight: [routes.EXPLANATION.path, routes.INFORMATION.path, routes.CONVERSATION.path]
}

export const PrivateLayout: React.FC<PrivateLayoutProps> = ({withHeader}) => {
    const location = useLocation()
    const {setGeneralData} = useGeneralStore()
    const orientation = useOrientation()
    const isConversation = location.pathname === routes.CONVERSATION.path
    const isSelectPatientPage = location.pathname === routes.PATIENTS.path

    const background: BackgroundType = useMemo(() => {
        for (const [style, routeList] of Object.entries(routesForBackground)) {
            if (routeList.includes(location.pathname)) {
                return style as BackgroundType
            }
        }
        return null
    }, [location.pathname])

    useEffect(() => {
        if (background) {
            setGeneralData('background', background)
        }
    }, [background, setGeneralData])

    const isOrientationLandscape = useMemo(() => {
        return orientation !== orientationType.portraitPrimary && orientation !== orientationType.portrait
    }, [orientation])

    if (isOrientationLandscape && !isConversation) {
        return <LandscapeView />
    } else if (isConversation) {
        return (
            <>
                {isOrientationLandscape && <LandscapeView />}
                <div style={{display: isOrientationLandscape ? 'none' : 'block'}}>
                    <Outlet />
                </div>
            </>
        )
    } else {
        return (
            <StyledMain background={background} $isSelectPatientPage={isSelectPatientPage}>
                {withHeader && <Header background={background} />}
                <Container fullHeight={!isSelectPatientPage}>
                    <Outlet />
                </Container>
            </StyledMain>
        )
    }
}
