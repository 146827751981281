import {useEffect, useState} from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import {routes} from '@utilities/constants/routes.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useGeneralStore} from '@/store/useGeneralStore.tsx'
import {socket} from '@/socket'
import {useValidateSession} from '@/features/validate-session/queries/useValidateSession.ts'

export const PrivateRoute = () => {
    const {mutateAsync, isError} = useValidateSession()
    const [isPending, setIsPending] = useState(true)
    const [callCount, setCallCount] = useState(0)
    const {sid, isSessionValid, setGeneralData} = useGeneralStore()

    useEffect(() => {
        const validateSession = async () => {
            try {
                if (sid) {
                    const {data} = await mutateAsync(sid as string)
                    setGeneralData('isSessionValid', data.sessionEnabled)
                    if (data.sessionEnabled) {
                        setGeneralData('sid', data.sessionId)
                    }
                }
            } catch (error) {
                console.error('Error in session validation:', error)
            } finally {
                setIsPending(false)
            }
        }

        if (!isSessionValid) {
            validateSession()
        }

        // Incrementa il conteggio delle chiamate
        setCallCount(prevCount => prevCount + 1)
    }, [])

    // Controlla il conteggio delle chiamate e reindirizza se necessario
    useEffect(() => {
        if (callCount > 3) {
            setIsPending(false)
            setGeneralData('isSessionValid', false)
        }

        const timeout = setTimeout(() => {
            setCallCount(0) // Reimposta il conteggio dopo 5 secondi
        }, 5000)

        return () => clearTimeout(timeout)
    }, [callCount])

    // Disconnette il client
    useEffect(() => {
        const handleBeforeUnload = () => {
            if (sid) {
                socket.emit('message', {
                    event: 'disconnect',
                    sessionId: sid
                })
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload) // per windows e android
        window.addEventListener('pagehide', handleBeforeUnload) // per ios
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
            window.removeEventListener('pagehide', handleBeforeUnload)
        }
    }, [])

    if (isPending) {
        return <Spinner size={50} />
    }

    if (isError) {
        setGeneralData('isSessionValid', false)
        setGeneralData('sid', null)
    }

    return isSessionValid ? <Outlet /> : <Navigate to={routes.NO_AVAILABLE.path} />
}
