import {StyledSpinner, StyledSpinnerOverlay} from '@components/ui/spinner/style.ts'
interface SpinnerProps {
    color?: string
    size: number
}

export const Spinner = ({color = '#fff', size = 40}: SpinnerProps) => (
    <StyledSpinnerOverlay>
        <StyledSpinner color={color} size={size} />
    </StyledSpinnerOverlay>
)

Spinner.displayName = 'Spinner'
