import {StyledWarningModal, StyledWarningModalButton} from '@pages/conversation/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {AlertIcon} from '@components/ui/icon/Icon.tsx'
import {Trans, useTranslation} from 'react-i18next'
import {Button} from '@components/ui/button/Button.tsx'
import {useGeneralStore} from '@/store/useGeneralStore.tsx'
import {useLocation, useNavigate} from 'react-router-dom'
import useSequentialRoutes from '@hooks/useSequentialRoutes.tsx'

export const ModalConfirmationToLeave = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const {setGeneralData} = useGeneralStore()
    const {goToPreviousRoute} = useSequentialRoutes(navigate, location)

    const goBackToPatientsPage = () => {
        setGeneralData('openModalToLeave', false)
        goToPreviousRoute()
    }

    return (
        <>
            <StyledWarningModal width={400}>
                <Flexbox direction={'column'} gap={8} justify={'center'} align={'flex-start'}>
                    <Flexbox direction={'column'} gap={4} align={'center'}>
                        <AlertIcon />
                        <h3 className={'title'}>{t('patients:modal:title')}</h3>
                        <Trans
                            i18nKey={'patients:modal:content'}
                            components={{
                                strong: <strong />,
                                p: <p className={'content'} />
                            }}
                        />
                    </Flexbox>

                    <StyledWarningModalButton direction={'column'} gap={4} justify={'center'} align={'center'}>
                        <Button
                            className={'button'}
                            variant={'primary'}
                            onClick={() => {
                                setGeneralData('openModalToLeave', false)
                            }}
                        >
                            {t('patients:modal:remain')}
                        </Button>
                        <p className={'link'} onClick={goBackToPatientsPage}>
                            {t('patients:modal:leave')}
                        </p>
                    </StyledWarningModalButton>
                </Flexbox>
            </StyledWarningModal>
        </>
    )
}
