import {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {MessageType, OnErrorParams, OnMessageParams, OnPongParams} from '@/features/conversation/types.ts'
import {socket} from '@/socket'
import {useLocation, useNavigate} from 'react-router-dom'
import useSequentialRoutes from '@/hooks/useSequentialRoutes'
import {MessageTypeEnum, SocketEventEnum} from '@/utilities/constants/enums'
import {useGeneralStore} from '@/store/useGeneralStore.tsx'
import {useGetPatients} from '@/features/validate-session/queries/useGetPatients.ts'
import {PatientType} from '@/features/patient/types.ts'
import {ConversationFooter} from '@/features/conversation/components/conversation-footer'
import {StyledBackgroundConversation} from '@pages/conversation/style.ts'
import {ConversationBody} from '@/features/conversation/components/conversation-body/components'
import {ModalAgreeToCondition} from '@/features/conversation/components/modals/ModalAgreeToCondition.tsx'
import {ModalConfirmationToLeave} from '@/features/conversation/components/modals/ModalConfirmationToLeave.tsx'
import {routes} from '@utilities/constants/routes.tsx'
import toast from 'react-hot-toast'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {RemoveScroll} from 'react-remove-scroll'
import useDetectKeyboardOpen from 'use-detect-keyboard-open'

export const Conversation = () => {
    const {t} = useTranslation()
    const conversationWrapperRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const location = useLocation()
    const {goToNextRoute} = useSequentialRoutes(navigate, location)
    const [isLoading, setIsLoading] = useState(false)
    const {conversationId, selectedPatient, sid, isFullConversation, openModalToLeave, setGeneralData} =
        useGeneralStore()
    const {data} = useGetPatients()
    const patients = data?.data as PatientType[]
    const patient = patients?.find(patient => patient.id === selectedPatient)
    const [isPlayingAnimation, setIsPlayingAnimation] = useState(false)

    const [conversationMessages, setConversationMessages] = useState<MessageType[]>([
        {
            text: t('conversation:main:welcome_message'),
            isMine: false,
            sentiment: null,
            type: MessageTypeEnum.FirstMessage
        }
    ])
    const [openModalToAgree, setOpenModalToAgree] = useState(false)
    const [lastSentMessageIndex, setLastSentMessageIndex] = useState<number | null>(null)
    const {height: viewportHeight} = useWindowDimensions()
    const conditionToSeeAgreeModal = openModalToAgree && !openModalToLeave
    const isKeyboardOpen = useDetectKeyboardOpen()

    useEffect(() => {
        setOpenModalToAgree(true)
    }, [])

    const onSend = (message: MessageType) => {
        setConversationMessages(prevMessages => [...prevMessages, message])
        setIsLoading(true)
        setLastSentMessageIndex(conversationMessages.length)
        socket.emit(SocketEventEnum.Message, {
            event: SocketEventEnum.Message,
            sessionId: sid,
            message: message.text,
            conversationId: conversationId
        })
    }

    useEffect(() => {
        if (isFullConversation) {
            conversationWrapperRef.current?.scrollTo({
                top: conversationWrapperRef.current?.scrollHeight,
                behavior: 'smooth'
            })
        }
    }, [conversationMessages, isFullConversation])

    useEffect(() => {
        const onMessage = (data: OnMessageParams) => {
            setIsLoading(false)
            const updatedMessages = [...conversationMessages]

            updatedMessages.push({
                isMine: false,
                sentiment: data.sentiment,
                text: data.response,
                relevanceScore: data.relevanceScore,
                isAnimated: true
            })

            if (lastSentMessageIndex !== null) {
                updatedMessages[lastSentMessageIndex].relevanceScore = data.relevanceScore
            }

            //set all messages with isAnimated to false
            updatedMessages.forEach((message, index) => {
                if (index !== updatedMessages.length - 1) {
                    message.isAnimated = false
                }
            })
            setConversationMessages(updatedMessages)
        }
        socket.on(SocketEventEnum.Message, onMessage)

        const onError = (error: OnErrorParams) => {
            setIsLoading(false)
            if (error.code === 403 && !error.isSessionEnabled) {
                toast.error(error.message)
                localStorage.clear()
                window.location.reload()
            } else if (error.code === 403 && !error.isConversationEnabled) {
                toast.error(error.message)
                setGeneralData('isConversationValid', false)
                goToNextRoute()
                navigate(routes.THANK_YOU.path)
            } else {
                setConversationMessages(prevMessages => [
                    ...prevMessages,
                    {
                        isMine: false,
                        sentiment: null,
                        text: t('conversation:main:error_message'),
                        type: MessageTypeEnum.Error
                    }
                ])
            }
        }
        socket.on(SocketEventEnum.Error, onError)

        return () => {
            socket.off(SocketEventEnum.Message, onMessage)
            socket.off(SocketEventEnum.Error, onError)
        }
    }, [conversationMessages, lastSentMessageIndex])

    const TIMEOUT = 60000 // 1 minute
    useEffect(() => {
        const intervalId = setInterval(() => {
            socket.emit('ping')
        }, TIMEOUT)

        const onPong = (data: OnPongParams) => {
            setGeneralData('isSessionValid', data.sessionEnabled)
        }
        socket.once('pong', onPong)

        return () => {
            socket.off('pong', onPong)
            clearInterval(intervalId)
        }
    }, [])

    useEffect(() => {
        if (isLoading) {
            setConversationMessages(prevMessages => [
                ...prevMessages,
                {
                    isMine: false,
                    sentiment: null,
                    text: `${patient?.name} ${t('conversation:history:typing')}`,
                    type: MessageTypeEnum.Typing
                }
            ])
        } else {
            setConversationMessages(prevMessages => prevMessages.filter(message => message.type !== 'typing'))
        }

        setGeneralData('isTyping', isLoading)
    }, [isLoading])

    return (
        <>
            <RemoveScroll enabled={isKeyboardOpen}>
                <StyledBackgroundConversation
                    $viewportHeight={viewportHeight}
                    $isFullConversation={isFullConversation}
                    id={'my-scrollable-content'}
                >
                    <ConversationBody
                        setIsPlayingAnimation={setIsPlayingAnimation}
                        isTyping={isLoading}
                        patient={patient as PatientType}
                        conversationMessages={conversationMessages}
                        setConversationMessages={setConversationMessages}
                        conversationWrapperRef={conversationWrapperRef}
                        conditionToSeeAgreeModal={conditionToSeeAgreeModal}
                        isPlayingAnimation={isPlayingAnimation}
                        onSend={onSend}
                    />
                    {isFullConversation && (
                        <ConversationFooter
                            isPlayingAnimation={isPlayingAnimation}
                            isTyping={isLoading}
                            patient={patient as PatientType}
                            onSend={onSend}
                        />
                    )}
                </StyledBackgroundConversation>
            </RemoveScroll>

            {conditionToSeeAgreeModal && <ModalAgreeToCondition setOpenModalToAgree={setOpenModalToAgree} />}

            {openModalToLeave && <ModalConfirmationToLeave />}
        </>
    )
}
