import styled, {css} from 'styled-components'
import {BackgroundType} from '@/store/slices/types.ts'

export const HeaderWrapper = styled.div`
    ${({theme}) => css`
        box-sizing: border-box;
        padding: ${theme.spacing * 1.5}px ${theme.spacing * 6.5}px ${theme.spacing * 6}px ${theme.spacing * 6.5}px;
        z-index: 1;
    `}
`
type StyledContentProps = {
    background: BackgroundType
}
export const StyledContent = styled.div<StyledContentProps>`
    ${({theme, background}) => css`
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            width: 57px;
            height: 30px;
        }
        & path {
            fill: ${background === 'backgroundDark' ? theme.palette.neutral['white'] : theme.palette.primary['950']};
        }
    `}
`

type StyledHeaderForConversationProps = {
    $isFullConversation: boolean
    $conditionToSeeAgreeModal: boolean
}
export const StyledHeaderForConversation = styled.div<StyledHeaderForConversationProps>`
    ${({theme, $isFullConversation, $conditionToSeeAgreeModal}) => css`
        ${!$isFullConversation
            ? css`
                  color: ${theme.palette.neutral.white};

                  .history-icon {
                      & path {
                          stroke: ${theme.palette.neutral.white};
                      }
                  }
              `
            : css`
                  color: ${theme.palette.primary[950]};

                  .logo {
                      & path {
                          fill: ${theme.palette.primary[950]};
                      }
                  }
              `}
        ${theme.userSelect.none}
        background: transparent;
        z-index: ${$conditionToSeeAgreeModal ? 10 : 11};
        padding: ${theme.spacing * 6}px;
        -webkit-tap-highlight-color: transparent;

        .logo {
            width: 57px;
            height: 30px;
        }

        .icon-container {
            cursor: pointer;
            ${theme.typography.textSm}
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: ${theme.spacing}px;
        }
    `}
`
