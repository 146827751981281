import styled, {css} from 'styled-components'

export const StyledVideo = styled.video`
    ${() => css`
        width: 100%;
        height: 100%;
        object-fit: cover;
    `}
`

type StyledBackgroundProps = {
    $previewImage: string
}

export const StyledBackground = styled.div<StyledBackgroundProps>`
    ${({$previewImage}) => css`
        width: 100%;
        height: 100%;
        background-image: url(${$previewImage});
        background-size: cover;
        background-position: center;
    `}
`
