import {Modal} from '@/components/ui/modal/Modal'
import styled, {css} from 'styled-components'

export const StyledInformationModal = styled(Modal)`
    ${({theme: {spacing, userSelect}}) => css`
        padding: ${spacing * 6}px;
        border-radius: ${spacing * 6}px;
        ${userSelect.none}
    `}
`
