import styled, {css} from 'styled-components'

export const StyledLayout = styled.div`
    ${() => css`
        display: flex;
        flex-direction: column;
        height: 100vh;
    `}
`

export const StyledTitle = styled.h1`
    ${({theme: {typography, spacing}}) => css`
        ${typography.displayXs}
        font-weight: 700;
        padding: ${spacing * 4}px 0 ${spacing * 8}px 0;
        text-align: center;
    `}
`

export const StyledList = styled.div`
    ${({theme}) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${theme.spacing * 6}px;

        .patient-card {
            margin-bottom: ${theme.spacing * 6}px;
        }
    `}
`
