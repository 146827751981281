import {
    MineLastMessage,
    StyledBody,
    StyledBotMessage,
    StyledContainerMainChat,
    StyledConversationHeaderWrapper,
    StyledEllipses,
    StyledFooter,
    StyledGradient,
    StyledMineMessageMainChat,
    StyledVideo,
    StyledWrapperMainChat
} from '@/features/conversation/components/conversation-body/components/main-chat/style.ts'
import {ConversationHeader} from '@/features/conversation/components/conversation-header/ConversationHeader.tsx'
import {PatientType} from '@/features/patient/types.ts'
import {FC, useEffect, useMemo} from 'react'
import {EmpathyAvatar} from '@components/ui/empathy-avatar/EmpathyAvatar.tsx'
import {MessageType} from '@/features/conversation/types.ts'
import {StyledTypingMessage} from '@/features/conversation/components/conversation-body/components/message/style.ts'
import ThreeDotsAnimation from '@/features/conversation/components/conversation-footer/components/dots-animation/DotsAnimation.tsx'
import {SocketEventEnum} from '@utilities/constants/enums.ts'
import useAnimatedText from '@hooks/useAnimatedText.ts'
import {useTranslation} from 'react-i18next'
import {StyledMineMessage} from '@/features/conversation/components/conversation-body/components/style.ts'
import {ConversationFooter} from '@/features/conversation/components/conversation-footer'
import {useGeneralStore} from '@/store/useGeneralStore.tsx'
import useDetectKeyboardOpen from 'use-detect-keyboard-open'

type MainChatProps = {
    patient: PatientType
    conditionToSeeAgreeModal: boolean
    setIsPlayingAnimation: (value: boolean) => void
    conversationMessages: MessageType[]
    lastMessagePatient: MessageType | undefined
    isTyping: boolean
    onAnimationComplete: () => void
    lastMessageMine: MessageType | undefined
    isPlayingAnimation: boolean
    onSend: (message: MessageType) => void
}

export const MainChat: FC<MainChatProps> = ({
    patient,
    conditionToSeeAgreeModal,
    setIsPlayingAnimation,
    conversationMessages,
    lastMessagePatient,
    isTyping,
    onAnimationComplete,
    lastMessageMine,
    isPlayingAnimation,
    onSend
}) => {
    const {t} = useTranslation()
    const {rows} = useGeneralStore()
    const isKeyboardOpen = useDetectKeyboardOpen()
    const lastMessagePatientTextLength = useMemo(
        () => lastMessagePatient?.text.trim().split(/\s+/).length,
        [lastMessagePatient?.text]
    )

    const {displayedText, isAnimating} = useAnimatedText(
        lastMessagePatient?.text ?? '',
        lastMessagePatient?.isAnimated ?? false,
        onAnimationComplete
    )

    const windowInnerHeight = window.innerHeight
    //scroll at the bottom of the chat when the displayedText is rendered
    const scrollAtBottom = () => {
        const element = document.getElementById('bot-message')
        if (element) {
            element?.scrollTo({
                top: element.scrollHeight,
                behavior: 'smooth'
            })
        }
    }

    useEffect(() => {
        scrollAtBottom()
    }, [displayedText])

    return (
        <StyledWrapperMainChat>
            <StyledBody>
                <StyledContainerMainChat>
                    {/*header*/}
                    <StyledConversationHeaderWrapper>
                        <ConversationHeader
                            patient={patient as PatientType}
                            conditionToSeeAgreeModal={conditionToSeeAgreeModal}
                            setIsPlayingAnimation={setIsPlayingAnimation}
                        />
                    </StyledConversationHeaderWrapper>

                    {/*body background*/}
                    <StyledEllipses />

                    <StyledGradient $isKeyboardOpen={isKeyboardOpen} $rows={rows} />
                    <StyledVideo $isKeyboardOpen={isKeyboardOpen} $rows={rows} $windowInnerHeight={windowInnerHeight}>
                        <EmpathyAvatar
                            sentiment={conversationMessages[conversationMessages.length - 1].sentiment ?? null}
                            setIsPlayingAnimation={setIsPlayingAnimation}
                            patient={patient}
                            isAnimating={isAnimating}
                            lastMessagePatientTextLength={lastMessagePatientTextLength}
                        />
                    </StyledVideo>

                    {/*message from bot*/}
                    <StyledBotMessage
                        id={'bot-message'}
                        $isKeyboardOpen={isKeyboardOpen}
                        $isFirstMessage={conversationMessages.length === 1}
                        $errorMessageType={lastMessagePatient?.type === SocketEventEnum.Error}
                    >
                        {isTyping ? (
                            <>
                                <StyledTypingMessage $isFullConversation={false}>
                                    <ThreeDotsAnimation />
                                    <p>{lastMessagePatient?.text}</p>
                                </StyledTypingMessage>

                                {isKeyboardOpen && <MineLastMessage>{lastMessageMine?.text}</MineLastMessage>}
                            </>
                        ) : (
                            displayedText
                        )}
                    </StyledBotMessage>
                </StyledContainerMainChat>
            </StyledBody>
            <StyledFooter $haveGap={!lastMessageMine || (lastMessageMine && lastMessageMine.text.length < 50)}>
                {!isKeyboardOpen && (
                    <StyledMineMessageMainChat>
                        {lastMessageMine ? (
                            <StyledMineMessage $isPlaceholder={false}>{lastMessageMine?.text}</StyledMineMessage>
                        ) : (
                            <StyledMineMessage $isPlaceholder>
                                {t('conversation:main:mine_message_placeholder')}
                            </StyledMineMessage>
                        )}
                    </StyledMineMessageMainChat>
                )}
                <ConversationFooter
                    isPlayingAnimation={isPlayingAnimation}
                    isTyping={isTyping}
                    patient={patient as PatientType}
                    onSend={onSend}
                />
            </StyledFooter>
        </StyledWrapperMainChat>
    )
}
