import {MessageType} from '@/features/conversation/types.ts'
import {FC, useState} from 'react'
import {
    StyledContainerPatientMessage,
    StyledErrorMessage,
    StyledMessageContainer,
    StyledName,
    StyledReadMore,
    StyledText,
    StyledTypingMessage
} from '@/features/conversation/components/conversation-body/components/message/style.ts'
import {PatientType} from '@/features/patient/types.ts'
import {MessageTypeEnum} from '@utilities/constants/enums.ts'
import ThreeDotsAnimation from '@/features/conversation/components/conversation-footer/components/dots-animation/DotsAnimation.tsx'
import {useTranslation} from 'react-i18next'
import {truncateText} from '@utilities/helpers.ts'
import useAnimatedText from '@hooks/useAnimatedText.ts'

type PatientMessageProps = {
    message: MessageType
    isTyping: boolean
    patient: PatientType
    onAnimationComplete: () => void
}

export const PatientMessage: FC<PatientMessageProps> = ({onAnimationComplete, patient, message}) => {
    const {t} = useTranslation()
    const [isReadMore, setIsReadMore] = useState(false)
    const {displayedText} = useAnimatedText(message.text, message.isAnimated ?? false, onAnimationComplete)

    const messageToRender = () => {
        switch (true) {
            case message.isAnimated:
                return displayedText
            case message.type === MessageTypeEnum.Typing:
                return (
                    <StyledTypingMessage $isFullConversation>
                        <ThreeDotsAnimation />
                        <p>{message.text}</p>
                    </StyledTypingMessage>
                )
            case message.text.length > 300:
                return (
                    <div>
                        {isReadMore ? displayedText : truncateText(displayedText, 200)}
                        <StyledReadMore onClick={() => setIsReadMore(!isReadMore)}>
                            {t(isReadMore ? 'conversation:history:read_less' : 'conversation:history:read_more')}
                        </StyledReadMore>
                    </div>
                )
            case message.type === MessageTypeEnum.Error:
                return <StyledErrorMessage>{displayedText}</StyledErrorMessage>
            default:
                return message.text
        }
    }

    return (
        <StyledMessageContainer>
            <StyledName>{patient?.name ?? ''}</StyledName>
            <StyledContainerPatientMessage $isFirstMessage={message.type === MessageTypeEnum.FirstMessage}>
                <StyledText $isMine={message.isMine}>{messageToRender()}</StyledText>
            </StyledContainerPatientMessage>
        </StyledMessageContainer>
    )
}
