import axios from 'src/axiosInstance.ts'

export const VALIDATE_API = {
    getSessionId: () => axios.post('/sessions'),
    validateSession: (sid: string) => axios.post(`/sessions/validate/${sid}`),
    getPatients: () => axios.get('/patients'),
    sendSelectedPatient: (data: {sessionId: string; patientId: number}) =>
        axios.post(`/conversations`, {
            patientId: data.patientId,
            sessionId: data.sessionId
        })
}
