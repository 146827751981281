import {useNavigate} from 'react-router-dom'
import {ChevronLeftIcon} from '../icon/Icon'
import {StyledMainWrapper} from './style'
import {useTranslation} from 'react-i18next'
import {useGeneralStore} from '@/store/useGeneralStore.tsx'

export const BackButton = ({onBack}: {onBack?: () => void}) => {
    const {background} = useGeneralStore()
    const {t} = useTranslation()
    const navigate = useNavigate()

    return (
        <StyledMainWrapper
            background={background}
            onClick={() => (typeof onBack === 'function' ? onBack() : navigate(-1))}
        >
            <ChevronLeftIcon viewBox={'0 0 5 9'} className={'icon'} />
            <p>{t('commons:back')}</p>
        </StyledMainWrapper>
    )
}

BackButton.displayName = 'BackButton'
