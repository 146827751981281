import {useTranslation} from 'react-i18next'
import {AlertCircleIcon} from '@components/ui/icon/Icon.tsx'
import {
    StyledContainerNoLongerAvailable,
    StyledContainerNoLongerAvailableText
} from '@pages/no-longer-available/styles.ts'

export const NoLongerAvailable = () => {
    const {t} = useTranslation()
    return (
        <StyledContainerNoLongerAvailable align={'center'} justify={'center'} gap={8} direction={'column'}>
            <AlertCircleIcon size={87} />
            <StyledContainerNoLongerAvailableText direction={'column'} gap={1} justify={'center'} align={'center'}>
                <h3>{t('no_longer_available:title')}</h3>
                <p>{t('no_longer_available:subtitle')}</p>
            </StyledContainerNoLongerAvailableText>
        </StyledContainerNoLongerAvailable>
    )
}
