import React from 'react'
import {StyledDot} from '@/features/conversation/components/conversation-footer/components/dots-animation/styles.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useGeneralStore} from '@/store/useGeneralStore.tsx'

const ThreeDotsAnimation: React.FC = () => {
    const {isFullConversation} = useGeneralStore()

    return (
        <Flexbox direction={'row'} gap={0.5} justify={'flex-start'} align={'center'}>
            <StyledDot delay="0s" $isFullConversation={isFullConversation} />
            <StyledDot delay=".5s" $isFullConversation={isFullConversation} />
            <StyledDot delay="1s" $isFullConversation={isFullConversation} />
        </Flexbox>
    )
}

export default ThreeDotsAnimation
