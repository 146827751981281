import * as pages from '@/pages'

export const routes = {
    WELCOME: {
        path: '/',
        element: <pages.Welcome />,
        handle: {
            path: '/',
            paramName: ''
        }
    },
    DISCLAIMER: {
        path: '/disclaimer',
        element: <pages.Disclaimer />,
        handle: {
            path: '/disclaimer',
            paramName: ''
        }
    },
    EXPLANATION: {
        path: '/explanation',
        element: <pages.Explanation />,
        handle: {
            path: '/explanation',
            paramName: ''
        }
    },
    INFORMATION: {
        path: '/information',
        element: <pages.Information />,
        handle: {
            path: '/information',
            paramName: ''
        }
    },

    PATIENTS: {
        path: '/patients',
        element: <pages.Patients />,
        handle: {
            path: '/patients',
            paramName: ''
        }
    },
    CONVERSATION: {
        path: '/conversation',
        element: <pages.Conversation />,
        handle: {
            path: '/conversation',
            paramName: ''
        }
    },
    THANK_YOU: {
        path: '/thank-you',
        element: <pages.ThankYou />,
        handle: {
            path: '/thank-you',
            paramName: ''
        }
    },
    NO_AVAILABLE: {
        path: '/no-available',
        element: <pages.NoLongerAvailable />,
        handle: {
            path: '/no-available',
            paramName: ''
        }
    },

    NOT_FOUND: {
        path: '*',
        element: <pages.NotFound />,
        handle: {
            path: '*',
            paramName: ''
        }
    }
}
