import {StyledDescriptionOnPortrait, StyledLandscapeView} from '@components/ui/landscape-view/style.ts'
import {useTranslation} from 'react-i18next'
import rotateDevice from '@assets/images/rotate-device.gif'
import {useMemo} from 'react'

export const LandscapeView = () => {
    const {t} = useTranslation()

    const isDesktop = useMemo(() => {
        return window.innerWidth >= 1024
    }, [window.innerWidth])

    return (
        <StyledLandscapeView>
            <img src={rotateDevice} alt="rotate-device" />

            <StyledDescriptionOnPortrait>
                {t(isDesktop ? 'commons:use_smartphone' : 'commons:rotate_device')}
            </StyledDescriptionOnPortrait>
        </StyledLandscapeView>
    )
}
