import {useEffect, useState} from 'react'

export const orientationType = {
    landscape: 'landscape',
    portrait: 'portrait',
    landscapePrimary: 'landscape-primary',
    portraitPrimary: 'portrait-primary',
    landscapeSecondary: 'landscape-secondary',
    portraitSecondary: 'portrait-secondary'
}

const useOrientation = () => {
    const isIOS = () => {
        return /iPad|iPhone|iPod/.test(navigator.platform)
    }
    const [orientation, setOrientation] = useState(isIOS() ? getOrientation() : window.screen.orientation.type)

    function getOrientation() {
        return window.innerWidth > window.innerHeight ? orientationType.landscape : orientationType.portrait
    }

    function updateOrientation() {
        setOrientation(window.screen.orientation.type)
    }

    useEffect(() => {
        if (isIOS()) {
            window.matchMedia('(orientation: portrait)').addEventListener('change', e => {
                const portrait = e.matches

                if (portrait) {
                    setOrientation(orientationType.portrait)
                } else {
                    setOrientation(orientationType.landscape)
                }
            })
        } else {
            updateOrientation()
            // Add an event listener for orientation change
            window.addEventListener('orientationchange', updateOrientation)
            // Clean up the event listener when the component unmounts
            return () => {
                window.removeEventListener('orientationchange', updateOrientation)
            }
        }
    }, [window.innerWidth, window.innerHeight])

    return orientation
}

export default useOrientation
