import {useMutation} from '@tanstack/react-query'
import {MUTATION_KEYS} from '@/features/validate-session/keys.ts'
import {VALIDATE_API} from '@/features/validate-session/services/validate.http.ts'
import toast from 'react-hot-toast'

export const useSendPatient = () => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.SEND_PATIENT],
        mutationFn: (data: {sessionId: string; patientId: number}) => VALIDATE_API.sendSelectedPatient(data),
        onError: () => {
            toast.error('Error sending patient')
            localStorage.clear()
            window.location.reload()
        }
    })
}
