import {create} from 'zustand'
import {persist} from 'zustand/middleware'
import {createGeneralDataSlice} from '@/store/slices/generalStoreSlice.tsx'
import {GeneralStoreActions, GeneralStoreState} from '@/store/slices/types.ts'

export const useGeneralStore = create<GeneralStoreState & GeneralStoreActions>()(
    persist(
        (...a) => ({
            ...createGeneralDataSlice(...a)
        }),
        {name: 'general-store'}
    )
)
