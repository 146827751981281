export enum SentimentEnum {
    Anger = 'anger',
    Disgust = 'disgust',
    Fear = 'fear',
    Joy = 'joy',
    Sadness = 'sadness',
    Surprise = 'surprise'
}

export enum SocketEventEnum {
    Init = 'init',
    Message = 'message',
    Error = 'error'
}

export enum MessageTypeEnum {
    Error = 'error',
    Typing = 'typing',
    FirstMessage = 'firstMessage'
}

export enum ExplanationTabs {
    First = 0,
    Second = 1,
    Third = 2
}
