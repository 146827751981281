import styled, {css} from 'styled-components'

export const StyledContainerNotFound = styled.div`
    ${({theme}) => css`
        background: ${theme.gradients['backgroundDark']};
        color: ${theme.palette.neutral.white};
        height: 100vh;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 100px;
        font-weight: 700;

        .title {
            ${theme.typography.displayLg};
        }

        .subtitle {
            ${theme.typography.textXl};
        }

        & button {
            font-weight: 400;
        }
    `}
`
