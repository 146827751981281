import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import styled, {css} from 'styled-components'

export const StyledContainerDisclaimerPage = styled(Flexbox)`
    ${({theme: {userSelect, typography}}) => css`
        width: 100%;
        height: 100%;
        text-align: center;
        ${userSelect.none}
        ${typography.textMd}
        
        & span {
            max-width: 330px;
            font-weight: 700;
        }

        & p {
            max-width: 330px;
        }

        & button {
            width: 100%;
        }
    `}
`
