import React from 'react'
import {StyledContainerNotFound} from '@pages/not-found/style.ts'
import {LogoIcon, NotFoundIcon} from '@components/ui/icon/Icon.tsx'
import {routes} from '@utilities/constants/routes.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'

export const NotFound: React.FC = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    return (
        <StyledContainerNotFound>
            <LogoIcon width={81} height={41} viewBox="0 0 465 285" />

            <Flexbox direction={'column'} align={'center'} justify={'center'} gap={8}>
                <NotFoundIcon width="311" height="152" viewBox="0 0 311 152" fill="none" />

                <Flexbox direction={'column'} align={'center'} gap={2}>
                    <p className={'title'}>{t('not_found:title')}</p>
                    <p className={'subtitle'}>{t('not_found:subtitle')}</p>
                </Flexbox>

                <Button variant={'primary'} size={'xl'} onClick={() => navigate(routes.WELCOME.path)}>
                    {t('not_found:button')}
                </Button>
            </Flexbox>
        </StyledContainerNotFound>
    )
}
