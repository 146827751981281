import styled, {css} from 'styled-components'

export const StyledCardTitle = styled.h4`
    ${({theme: {typography, palette, spacing}}) => css`
        ${typography.textMd}
        font-weight: 500;
        color: ${palette.primary[950]};
        margin: ${spacing * 2.5}px 0;
    `}
`

export const StyledCardList = styled.ul`
    ${({theme: {typography, palette, spacing}}) => css`
        margin: 0;
        padding: 0 0 0 ${spacing * 4}px;
        list-style: disc;

        & li {
            ${typography.textSm}
            color: ${palette.primary[950]};
        }
    `}
`
