import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledContainerNoLongerAvailable = styled(Flexbox)`
    ${({theme}) => css`
        background: ${theme.gradients['backgroundLight']};
        height: 100%;
        width: 100%;
    `}
`

export const StyledContainerNoLongerAvailableText = styled(Flexbox)`
    ${({theme}) => css`
        padding: ${theme.spacing * 4}px ${theme.spacing * 6}px;
        text-align: center;
        color: ${theme.palette.primary['950']};

        h3 {
            ${theme.typography.textLg};
            font-weight: 600;
        }

        p {
            ${theme.typography.textMd};
        }
    `}
`
