import {FC} from 'react'
import {PatientCard} from '@/components/ui/patient-card/PatientCard'
import {useTranslation} from 'react-i18next'
import {StyledLayout, StyledList, StyledTitle} from './style'
import {useLocation, useNavigate} from 'react-router-dom'
import useSequentialRoutes from '@hooks/useSequentialRoutes.tsx'
import {useGeneralStore} from '@/store/useGeneralStore.tsx'
import {BackButton} from '@components/ui/back-button/BackButton.tsx'
import {useGetPatients} from '@/features/validate-session/queries/useGetPatients.ts'
import {useSendPatient} from '@/features/validate-session/queries/useSendPatient.ts'
import {PatientType} from '@/features/patient/types.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {socket} from '@/socket.ts'

export const Patients: FC = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const {goToPreviousRoute, goToNextRoute} = useSequentialRoutes(navigate, location)
    const {setGeneralData, sid} = useGeneralStore()
    const {mutateAsync} = useSendPatient()
    const handleActivateChat = async (patientId: number) => {
        try {
            setGeneralData('selectedPatient', patientId)
            const {data} = await mutateAsync({
                sessionId: sid as string,
                patientId
            })

            setGeneralData('conversationId', data.id)
            goToNextRoute()

            socket.emit('init', {
                event: 'init',
                sessionId: sid
            })
        } catch (error) {
            console.log(error)
        }
    }
    const {data, isLoading} = useGetPatients()
    const patients = data?.data as PatientType[]

    return (
        <>
            {isLoading && <Spinner size={50} />}
            <StyledLayout>
                <BackButton onBack={goToPreviousRoute} />
                <StyledTitle>{t('patients:list:title')}</StyledTitle>
                <StyledList>
                    {patients?.map((patient, index) => (
                        <PatientCard patient={patient} key={index} onClick={() => handleActivateChat(patient.id)} />
                    ))}
                </StyledList>
            </StyledLayout>
        </>
    )
}
