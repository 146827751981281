import styled, {css, DefaultTheme} from 'styled-components'

type StyledDotProps = {
    $isActive: boolean
}

type StyledWrapperProps = {
    theme: DefaultTheme
    $isVisible: boolean
}

export const StyledImageSlide = styled.img`
    ${() => css`
        max-width: 223px;
        height: 223px;
        max-height: 223px;
        margin: 70px auto 40px;

        animation: fadeIn 1s;
        @keyframes fadeIn {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    `}
`

export const StyledDot = styled.div<StyledDotProps>`
    ${({theme, $isActive}) => css`
        width: 106px;
        height: 2px;
        background-color: ${$isActive ? theme.palette.primary[950] : theme.palette.secondary[950]};
        border-radius: 9999px;
        cursor: pointer;
    `}
`

export const StyledWrapper = styled.div<StyledWrapperProps>`
    ${({$isVisible}) => css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: ${$isVisible ? 1 : 0};
        visibility: ${$isVisible ? 'visible' : 'hidden'};
        transition:
            opacity 0.5s ease,
            visibility 0.5s ease;

        .description {
            width: 100%;
            text-align: center;
            @media only screen and (min-width: 600px) {
                width: 50%;
            }
        }
    `}
`

export const StyledTitleSlide = styled.p`
    ${({theme: {spacing, typography}}) => css`
        ${typography.textXl};
        font-weight: 700;
        margin-bottom: ${spacing * 2}px;
    `}
`

export const StyledSubtitleSlide = styled.p`
    ${({theme: {typography}}) => css`
        ${typography.textMd};
    `}
`

export const StyledContainerSlider = styled.div`
    ${({theme: {userSelect}}) => css`
        position: relative;
        height: 244px;
        ${userSelect.none}
    `}
`

export const StyledArrow = styled.div`
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    width: 25%;
    height: 70%;
    cursor: pointer;
    z-index: 1;
    -webkit-tap-highlight-color: transparent;
    &.left {
        left: 10px;
    }

    &.right {
        right: 10px;
    }

    @media only screen and (min-width: 600px) {
        width: 50%;
    }
`
type StyledContainerButtonProps = {
    theme: DefaultTheme
    $currentSlide: number
}
export const StyledContainerButton = styled.div<StyledContainerButtonProps>`
    ${({theme, $currentSlide}) => css`
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: ${theme.spacing * 4}px;
        margin: ${theme.spacing * 4}px 0;
        font-weight: 700;

        ${$currentSlide !== 2 &&
        css`
            margin: 0;
            padding: 40px 0 0 0;
        `}

        .back-button,
        .next-button {
            padding: ${theme.spacing * 2}px;
            color: ${theme.palette.primary[950]};
            border-radius: ${theme.spacing}px;
            cursor: pointer;
        }
    `}
`
