import {Modal} from '@/components/ui/modal/Modal'
import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

type StyledContainerConversationProps = {
    $isFullConversation: boolean
    $viewportHeight: number
}

export const StyledBackgroundConversation = styled.div<StyledContainerConversationProps>`
    ${({theme, $isFullConversation, $viewportHeight}) => css`
        background: ${$isFullConversation && theme.gradients['backgroundLight']};
        display: flex;
        flex-direction: column;
        height: ${`${$viewportHeight}px`};
        width: 100%;
        overflow: hidden;
        max-height: 100vh;
        max-width: 100%;
        box-sizing: border-box;
        position: fixed;
    `}
`

export const StyledWarningModal = styled(Modal)`
    ${({theme: {typography, spacing, userSelect}}) => css`
        ${userSelect.none}
        padding: ${spacing * 6}px;
        border-radius: ${spacing * 6}px;

        .title {
            text-align: center;
            ${typography.displayXs};
            font-weight: 700;
        }

        .content {
            ${typography.textSm};
            font-weight: 400;
            text-align: center;

            & strong {
                font-weight: 700;
            }
        }
    `}
`

export const StyledAgreeModal = styled(StyledWarningModal)`
    ${({theme: {spacing, userSelect}}) => css`
        ${userSelect.none}
        .title {
            display: flex;
            align-items: center;
            gap: ${spacing * 2}px;
        }

        .content {
            text-align: left;
        }

        .button {
            width: 100%;
            height: 50px;
        }
    `}
`

export const StyledWarningModalButton = styled(Flexbox)`
    ${({theme: {palette, typography, spacing}}) => css`
        width: 100%;

        .button {
            ${typography.textMd};
            font-weight: 500;
            padding: ${spacing * 4}px ${spacing * 6}px;
            width: 100%;
            border-radius: ${spacing * 5}px;
        }

        .link {
            ${typography.textSm};
            text-align: center;
            padding: ${spacing * 4}px;
            width: 100%;
            color: ${palette.danger['500']};
            text-decoration: underline;
        }
    `}
`
