import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/features/validate-session/keys.ts'
import {VALIDATE_API} from '@/features/validate-session/services/validate.http.ts'

export const useGetSession = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_SESSION],
        queryFn: () => VALIDATE_API.getSessionId()
    })
}
