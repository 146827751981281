import styled, {css, keyframes} from 'styled-components'

export const StyledMainWrapper = styled.span`
    ${({theme: userSelect}) => css`
        ${userSelect.none}
        width: 100%;

        @media only screen and (min-width: 600px) {
            width: auto;
        }
    `}
`

type StyledCardProps = {
    background: string | undefined
}

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
`

export const StyledCard = styled.div<StyledCardProps>`
    ${({theme: {gradients, spacing, palette}, background}) => css`
        background:
            ${gradients[50]},
            url(${background}) no-repeat center;
        background-color: ${palette.primary[900]};
        animation: ${fadeIn} 0.2s ease-in-out;
        background-size: cover;

        padding: ${spacing * 2.5}px;
        width: 100%;
        height: 218px;
        border-radius: ${spacing * 6}px ${spacing * 6}px 0 0;

        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .patient-information {
            display: flex;
            flex-direction: column;
            gap: ${spacing * 2}px;
        }

        & button {
            border-radius: ${spacing * 4}px;
        }

        @media only screen and (min-width: 600px) {
            width: 600px;
        }
    `}
`

type StyledViewInformationButtonProps = {
    $isDropdownOpen: boolean
}
export const StyledViewInformationButton = styled.div<StyledViewInformationButtonProps>`
    ${({theme: {gradients, palette, spacing, typography}, $isDropdownOpen}) => css`
        border-radius: 0 0 ${spacing * 6}px ${spacing * 6}px;
        width: 100%;
        display: flex;
        ${typography.textSm};
        transition: background 0.3s ease-in-out;

        @media only screen and (min-width: 600px) {
            width: 600px;
        }

        ${!$isDropdownOpen
            ? css`
                  background: ${gradients[55]};
                  height: 45px;
                  align-items: center;
                  justify-content: center;
                  gap: ${spacing * 2}px;
              `
            : css`
                  background: ${palette.neutral.white};
                  height: 100%;
                  align-items: flex-start;
                  flex-direction: column;
                  justify-content: space-around;
                  gap: ${spacing * 6}px;
                  padding: ${spacing * 2}px ${spacing * 4}px;
                  ${typography.textSm};
              `}
    `}
`

export const StyledButtonToClose = styled.div<StyledViewInformationButtonProps>`
    ${({theme: {spacing, palette}, $isDropdownOpen}) => css`
        color: ${$isDropdownOpen ? palette.neutral.black : palette.neutral.white};
        text-align: center;
        cursor: pointer;
        display: flex;
        align-self: center;
        gap: ${spacing * 2}px;

        & svg {
            display: flex;
            align-self: center;
            height: 7px;
            width: 11px;
            rotate: ${$isDropdownOpen ? '180deg' : '0'};
            transition: rotate 0.3s ease-in-out;
            & path {
                stroke: ${$isDropdownOpen ? palette.neutral.black : palette.neutral.white};
            }
        }
    `}
`

export const StyledName = styled.span`
    ${({theme: {typography}}) => css`
        ${typography.displayXs}
        font-weight: 500;
    `}
`

export const StyledDescription = styled.span`
    ${({theme: {typography}}) => css`
        ${typography.textMd};
    `}
`
