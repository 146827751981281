import styled, {css, keyframes} from 'styled-components'

const waveAnimation = keyframes`
  0%, 60%, 100% {
    transform: initial;
  }
  30% {
    transform: translateY(-5px);
  }
`

type StyledDotProps = {
    delay?: string
    $isFullConversation: boolean
}
export const StyledDot = styled.div<StyledDotProps>`
    ${({theme: {palette}, delay, $isFullConversation}) => css`
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: ${$isFullConversation ? palette.neutral['black'] : palette.neutral['white']};
        animation: ${waveAnimation} 1.5s infinite ease-in-out;
        animation-delay: ${delay || '0s'};
    `}
`
