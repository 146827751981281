import {Navigate, Outlet} from 'react-router-dom'
import {useEffect} from 'react'
import {useGetSession} from '@/features/validate-session/queries/useGetSession.ts'
import {routes} from '@utilities/constants/routes.tsx'
import {useGeneralStore} from '@/store/useGeneralStore.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

export const PublicRoute = () => {
    const {setGeneralData, sid} = useGeneralStore()

    const {data, isLoading} = useGetSession()

    // Here the status is 422 Unprocessable Content
    // if the session can not be created since it is expired
    // it is needed to stop the users to access the application after a given time
    // defined at backend level
    useEffect(() => {
        if (data && data.status !== 422) {
            setGeneralData('sid', data.data.id)
        }
    }, [data])

    if (isLoading) {
        return <Spinner size={50} />
    }
    return sid ? <Navigate to={routes.WELCOME.path} /> : <Outlet />
}
