import {PatientType} from '@/features/patient/types'
import {useTranslation} from 'react-i18next'
import {StyledCardList, StyledCardTitle} from './style'

interface PatientInformationProps {
    patient: PatientType
}

export const PatientInformation = ({patient}: PatientInformationProps) => {
    const {t} = useTranslation()
    const biography = Object.values(patient.biography).flatMap(item => item)

    return (
        <>
            <div>
                <StyledCardTitle>{t('patients:details:biography_information')}</StyledCardTitle>
                <StyledCardList>
                    <li>
                        {patient.age} {t('patients:details:age')}
                    </li>
                    {biography.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </StyledCardList>
            </div>
            <div>
                <StyledCardTitle>{t('patients:details:clinical_history')}</StyledCardTitle>
                <StyledCardList>
                    {patient.clinicalHistory.map((item, index) => (
                        <li key={index}>{item.description}</li>
                    ))}
                </StyledCardList>
            </div>
            <div>
                <StyledCardTitle>{t('patients:details:symptoms')}</StyledCardTitle>
                <StyledCardList>
                    {patient.symptoms.map((item, index) => (
                        <li key={index}>{item.description}</li>
                    ))}
                </StyledCardList>
            </div>
        </>
    )
}

PatientInformation.displayName = 'PatientInformation'
