import styled, {css} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledParagraph = styled.p`
    ${({theme: {typography}}) => css`
        font-weight: 300;
        ${typography.displayXs};
    `}
`

export const StyledTitle = styled.h1`
    ${({theme: {spacing, typography}}) => css`
        font-weight: 700;
        ${typography.displayLg};
        margin-bottom: ${spacing * 8}px;
    `}
`

export const StyledSubtitle = styled.p`
    ${({theme: {typography, palette}}) => css`
        ${typography.textXl};
        color: ${palette.secondary['50']};
        text-align: center;
    `}
`

export const StyledStartButton = styled(Button)`
    ${({theme}) => css`
        padding: 16px 24px;
        width: 170px;
        border-radius: 20px;
        ${theme.typography.textMd};
        font-weight: 500;
        border: none;
    `}
`

export const StyledContainer = styled(Flexbox)`
    ${({theme: {userSelect}}) => css`
        width: 100%;
        height: 100%;
        text-align: center;
        ${userSelect.none}
    `}
`
