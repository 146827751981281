import {
    StyledContainer,
    StyledParagraph,
    StyledStartButton,
    StyledSubtitle,
    StyledTitle
} from '@pages/welcome/styles.ts'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'
import useSequentialRoutes from '@hooks/useSequentialRoutes.tsx'
import {LogoIcon} from '@components/ui/icon/Icon.tsx'

export const Welcome = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const {goToNextRoute} = useSequentialRoutes(navigate, location)

    return (
        <StyledContainer align={'center'} justify={'center'} gap={13} direction={'column'}>
            <LogoIcon width={80} height={40} viewBox="0 0 465 285" />
            <div>
                <StyledParagraph>{t('welcome_page:welcome')}</StyledParagraph>
                <StyledTitle>{t('welcome_page:title')}</StyledTitle>
                <StyledSubtitle>{t('welcome_page:subtitle')}</StyledSubtitle>
            </div>

            <StyledStartButton variant={'primary'} onClick={goToNextRoute}>
                {t('welcome_page:start')}
            </StyledStartButton>
        </StyledContainer>
    )
}
