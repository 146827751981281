import {PatientType} from '@/features/patient/types'
import {StyledCard, StyledDescription, StyledMainWrapper, StyledName} from './style'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {DropdownPatientCard} from '@components/ui/patient-card/components/DropdownPatientCard.tsx'
import {Button} from '../button/Button'
import FlorenceImage from '@assets/images/Florence.jpg'
import HenryImage from '@assets/images/Henry.jpg'

interface PatientCardProps {
    patient: PatientType
    onClick: () => void
}

export const PatientCard = ({patient, onClick}: PatientCardProps) => {
    const {t} = useTranslation()

    return (
        <StyledMainWrapper>
            <Flexbox direction={'column'} className={'patient-card'}>
                <StyledCard background={patient.id === 1 ? HenryImage : FlorenceImage}>
                    <div className={'patient-information'}>
                        <StyledName>{patient.name}</StyledName>
                        <StyledDescription>{patient.diagnosis}</StyledDescription>
                    </div>
                    <Button onClick={onClick}>{t('patients:card:start_chat')}</Button>
                </StyledCard>

                <DropdownPatientCard patient={patient} />
            </Flexbox>
        </StyledMainWrapper>
    )
}

PatientCard.displayName = 'PatientCard'
