import styled, {css} from 'styled-components'
import {LogoIcon} from '@components/ui/icon/Icon.tsx'

export const StyledThankYouTitle = styled.p`
    ${({theme}) => css`
        ${theme.typography.displayXs};
    `}
`

export const StyledLogoIcon = styled(LogoIcon)`
    ${() => css`
        width: 80px;
        height: 40px;
    `}
`

export const StyledThankYouSubtitle = styled.p`
    ${({theme}) => css`
        color: ${theme.palette.secondary[25]};
        ${theme.typography.textMd};
    `}
`

export const StyledLink = styled.p`
    ${({theme}) => css`
        margin-top: ${theme.spacing * 11}px;
        color: ${theme.palette.neutral['white']};
        ${theme.typography.textMd};
        cursor: pointer;
        text-decoration: underline;
    `}
`
