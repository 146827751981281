import styled, {css} from 'styled-components'

type StyledContainerProps = {
    $fullWidth: boolean
    $fullHeight: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
    ${({theme, $fullWidth, $fullHeight, theme: {spacing, mediaQueries}}) => css`
        width: 100%;
        height: ${$fullHeight ? '100%' : 'auto'};
        max-width: ${$fullWidth ? '100%' : '1280px'};
        margin: 0 auto;
        ${mediaQueries.l} {
            padding: 0 ${spacing * 6}px;
        }

        @media screen and (min-width: 769px) {
            padding: 0 ${theme.spacing * 6}px;
        }
    `}
`
