import {createGlobalStyle, withTheme} from 'styled-components'
import {palette} from '@/theme/palette.ts'
import LatoThin from '@/assets/fonts/Lato-Thin.woff2'
import LatoRegular from '@/assets/fonts/Lato-Regular.woff2'
import LatoLight from '@/assets/fonts/Lato-Light.woff2'
import LatoBold from '@/assets/fonts/Lato-Bold.woff2'

const ResetStyles = createGlobalStyle`
    * {
    '-ms-overflow-style': 'none' /* for Internet Explorer, Edge */,
    ' scrollbar-width': 'none' /* for Firefox */,
    }

    @font-face {
        font-family: 'Lato';
        src: url(${LatoThin}) format('woff2');
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: 'Lato';
        src: url(${LatoLight}) format('woff2');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'Lato';
        src: url(${LatoRegular}) format('woff2');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'Lato';
        src: url(${LatoBold}) format('woff2');
        font-weight: 700;
        font-style: normal;
    }

    html {
        -webkit-touch-callout: none; /* Disable iOS touch callout */
        overscroll-behavior: none; /* Disable pull-to-refresh and overscroll glow effect */

        height: 100%;
        padding: 0;
        margin: 0;
        font-family: 'Lato', sans-serif;
        color: ${palette.light.primary[950]};
        background-color: ${palette.light.neutral.white};
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    }

    body {
        padding: 0;
        margin: 0;
        height: 100%;
    }

    #root {
        height: 100%;
    }

    h1, h2, h3, h4, h5, h6, p, span, small {
        margin-bottom: 0;
    }

    label, input, textarea, select, button {
        font-family: inherit;
    }

    textarea {
        overflow-wrap: break-word;
    }

    //Reset Css

    /* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
    *:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
        all: unset;
        display: revert;
    }

    /* Preferred box-sizing value */
    *, *::before, *::after {
        box-sizing: border-box;
    }

    /* Remove list styles (bullets/numbers) */
    ol, ul {
        list-style: none;
    }

    /* For images to not be able to exceed their container */
    img {
        max-width: 100%;
    }

    svg {
        flex-shrink: 0;
    }

    /* Removes spacing between cells in tables */
    table {
        border-collapse: collapse;
    }

    /* Revert the 'white-space' property for textarea elements on Safari */
    textarea {
        white-space: revert;
    }

    /* Change the white to any color */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
`

export default withTheme(ResetStyles)
