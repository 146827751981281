import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledInformationWrapper = styled(Flexbox)`
    ${({theme: {userSelect}}) => css`
        width: 100%;
        height: 100%;
        ${userSelect.none}
        .privacy-policy-link {
            text-decoration: underline;
        }

        .wrapper-footer {
            width: 100%;
        }

        .text-wrapper {
            flex-grow: 1;

            p {
                white-space: pre-line;
            }
        }
    `}
`
export const StyledInformationTitle = styled.h3`
    ${({theme: {spacing, typography, palette}}) => css`
        font-weight: 600;
        ${typography.textLg};
        color: ${palette.neutral['900']};
        margin-bottom: ${spacing * 2.5}px;
    `}
`

export const StyledInformationButton = styled(Button)`
    ${() => css`
        width: 100%;
        height: 50px;
    `}
`
