import {StyledInputContainer, StyledSendButton} from './style'
import {PatientType} from '@/features/patient/types'
import {useTranslation} from 'react-i18next'
import {useEffect, useState} from 'react'
import {MessageType} from '@/features/conversation/types.ts'
import {SendIcon} from '@/components/ui/icon/Icon'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ResizableTextarea} from '@components/commons/resizable-textarea/ResizableTextArea.tsx'
import useDetectKeyboardOpen from 'use-detect-keyboard-open'

interface ConversationFooterProps {
    patient: PatientType
    onSend: (message: MessageType) => void
    isTyping: boolean
    isPlayingAnimation: boolean
}

export const ConversationFooter = ({patient, onSend, isTyping, isPlayingAnimation}: ConversationFooterProps) => {
    const {t} = useTranslation()
    const [message, setMessage] = useState('')
    const [resetRows, setResetRows] = useState(false)
    const isKeyboardOpen = useDetectKeyboardOpen()

    const sendMessage = () => {
        if (message.trim().length === 0 || isTyping || isPlayingAnimation) return
        if (message) {
            onSend({
                text: message,
                isMine: true,
                sentiment: null
            })
            setMessage('')
            setResetRows(true)
        }
    }

    useEffect(() => {
        if (!isKeyboardOpen) {
            setResetRows(true)
            setMessage('')
        }
    }, [isKeyboardOpen])

    const name = patient?.name ? patient.name : ''

    const checkIfIsATablet = () => {
        return window.innerWidth >= 768 && window.innerWidth <= 1024
    }

    return (
        <StyledInputContainer
            disabled={isTyping || isPlayingAnimation}
            onSubmit={e => {
                e.preventDefault()
                sendMessage()
                !checkIfIsATablet() && document.getElementById('textarea')?.focus()
            }}
        >
            <Flexbox className={'input-wrapper'} align={'center'}>
                <ResizableTextarea
                    placeholder={t('conversation:main:input_placeholder', {NAME: name})}
                    value={message}
                    onChange={e => {
                        if (isPlayingAnimation || isTyping) return
                        setMessage(e.target.value)
                    }}
                    name={'textarea'}
                    id={'textarea'}
                    resetRows={resetRows}
                    setResetRows={setResetRows}
                />
                {/*<EmojiMenu setMessage={setMessage} message={message} disabled={isTyping || isPlayingAnimation} />*/}
            </Flexbox>
            <StyledSendButton type={'submit'} disabled={message.trim().length === 0 || isTyping || isPlayingAnimation}>
                <SendIcon size={25} viewBox={'0 0 24 20'} />
            </StyledSendButton>
        </StyledInputContainer>
    )
}

ConversationFooter.displayName = 'ConversationFooter'
