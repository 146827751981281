import {css} from 'styled-components'
const defaultFontFamily = `'Lato', sans-serif`

export const typography = {
    displayLg: css`
        font-family: ${defaultFontFamily};
        font-size: 3rem; /* 48px */
        line-height: 60px;
        letter-spacing: -0.02em;
    `,
    displayXs: css`
        font-family: ${defaultFontFamily};
        font-size: 1.5rem; /* 24px */
        line-height: 32px;
    `,
    textXl: css`
        font-family: ${defaultFontFamily};
        font-size: 1.25rem; /* 20px */
        line-height: 30px;
    `,
    textLg: css`
        font-family: ${defaultFontFamily};
        font-size: 1.125rem; /* 18px */
        line-height: 28px;
    `,
    textMd: css`
        font-family: ${defaultFontFamily};
        font-size: 1rem; /* 16px */
        line-height: 24px;
    `,
    textSm: css`
        font-family: ${defaultFontFamily};
        font-size: 0.875rem; /* 14px */
        line-height: 20px;
    `,
    textXs: css`
        font-family: ${defaultFontFamily};
        font-size: 0.65rem; /* 10px */
        line-height: 16px;
    `
}
