import {MessageType} from '../../../../types.ts'
import {PatientType} from '@/features/patient/types.ts'
import {MineMessage} from '@/features/conversation/components/conversation-body/components/message/MineMessage.tsx'
import {PatientMessage} from '@/features/conversation/components/conversation-body/components/message/PatientMessage.tsx'
import {StyledMainWrapper} from '@/features/conversation/components/conversation-body/components/message/style.ts'

interface MessageProps {
    message: MessageType
    patient: PatientType
    isTyping: boolean
    onAnimationComplete: () => void
}

export const Message = ({message, patient, isTyping, onAnimationComplete}: MessageProps) => {
    return (
        <StyledMainWrapper $isMine={message.isMine}>
            {message.isMine ? (
                <MineMessage message={message} />
            ) : (
                <PatientMessage
                    onAnimationComplete={onAnimationComplete}
                    patient={patient}
                    isTyping={isTyping}
                    message={message}
                />
            )}
        </StyledMainWrapper>
    )
}

Message.displayName = 'Message'
